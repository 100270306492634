import { t } from "@lingui/macro";
import FormComponent from '../../../components/FormComponent';
import parseFormDefinition from '../../../util/parseFormDefinition';
import { FORM_ONLY_SUBMIT_BUTTON } from "../../../constants";
import ReportsApi from "../../../api/ReportsApi";
import UWEEntityApi from "../../../api/UWEEntityApi";

export const ReportForm = FormComponent({
    ...parseFormDefinition({
        displayName: 'RoleDetailsEditor',
        title: t`Role Details`,
        schemaProps: {
            "title": "Reportes",
            "ui:newSection": true,
            ":classNames": "grid-3-columns",
            "format": "date"
        },
        schema: {
            "type": {
                "type": "string",
                "title": "Seleccione un reporte",
                ":classNames": "from-col-1-size-1",
                "enumNames": ["Reporte de Servicios Mensuales"],
                "enum": ["MonthlyServicesReport"]
            },
            "dateFrom": {
                ":classNames": "from-col-2-size-1",
                "title": t`Fecha Desde`,
                "map:field": "dateFrom",
                "format": "date",
                "type": "string",
            },
            "dateTo": {
                ":classNames": "from-col-3-size-1",
                "title": t`Fecha Hasta`,
                "map:field": "dateTo",
                "format": "date",
                "type": "string",
            },
        },
    }),
    withoutNavigation: true,
    hideDataLoader: true,
    onSubmit: async ({ formData }) => {
        const referenceId = await ReportsApi.getReferenceId(formData);
        if (referenceId) {
            const response = await UWEEntityApi.verifyPreviewGeneration({ referenceId });
            const openUrl = response?.items?.[0]?.uri;
            if (openUrl) {
                window.open(openUrl);
            }
        }
    },
    submitButtons: FORM_ONLY_SUBMIT_BUTTON,
});

export default ReportForm;