
import React, { useState, useEffect } from 'react';
import { Card, Button, CardFooter, CardHeader, CardBody } from 'reactstrap';
import { useHistory } from 'react-router';
import ReactQuill from 'react-quill';
import ManageFaqApi from '../../../api/ManageFaqApi';
import useResourceLoader from '../../../util/useResourceLoader';
import useLoader from '../../../util/useLoader';
import Loader from '../../../components/Loader';
import 'react-quill/dist/quill.snow.css';

function AddEditFaq(props) {

  const history = useHistory();

  const { id, setFaq } = props;

  const [faq, loadingFaq] = useResourceLoader(
    () => id ? ManageFaqApi.getFaq({ id }) : [],
    [id],
    []
  );

  const [formState, setFormState] = useState(null);
  const [loadingSave, errorLoadingSave, loadFn] = useLoader();

  const loading = loadingFaq || loadingSave;

  useEffect(() => {
    setFormState({
      id: faq?.id ?? null,
      content: faq?.content ?? "",
    });
  }, [faq]);

  const handleChange = (value) => {
    setFormState((prevState) => ({
      ...prevState,
      content: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    return loadFn(async () => {
      await (!formState?.id ? ManageFaqApi.createFaq(formState) : ManageFaqApi.updateFaq(faq.id, formState));
      setFaq(null)
    });
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false
    }
  };

  const formats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'link', 'image', 'video'
  ];

  return (
    loading ? <Loader /> :
      <>
        <Card>
          <CardHeader>
            {!faq.id ? 'Crear FAQ' : `Modificar FAQ #${faq.id}`}
          </CardHeader>
          <CardBody style={{ height: '400px' }}>
            <ReactQuill
              modules={modules}
              formats={formats}
              value={formState?.content}
              name="content"
              onChange={handleChange}
              placeholder="Enter the text with HTML format"
            />
          </CardBody>
          <CardFooter>
            <Button color="primary" type="submit" onClick={() => history.push(`/system-settings/faq/`)}>
              Volver
            </Button>
            <Button color="success" type="submit" onClick={handleSubmit}>
              Guardar
            </Button>
          </CardFooter>
        </Card>
      </>
  );
};

export default AddEditFaq;