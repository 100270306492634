import React, { useMemo } from "react";
import Jnx, { parseJnxExpr } from "../../../util/jnx";

const REQUIRED_IF_JNX = (jnx) => {
    if (jnx){
        jnx = parseJnxExpr(jnx);
        jnx.expr = `($isTruthy(${jnx.expr}) and $isEmpty($value)) ? true : false`;
        return new Jnx(jnx);
    }
    return false;
};

function FieldTitle({
    idSchema: { $id },
    schema: {
        title : propTitle
    },
    uiSchema: {
        'akc:requiredIfVisible': requiredIfVisible,
        'akc:requiredIf': requiredIfExpr,
    },
    required,
}) {
    const requiredField = !!required || !!requiredIfVisible || REQUIRED_IF_JNX(requiredIfExpr)

    const title = useMemo(() => (propTitle || '').trim(), [propTitle]);

    return (title.trim() ? (<label className="control-label" htmlFor={$id}>
        {title}{requiredField ? <span className="required">*</span> : null}
    </label>) : null);
}


export default FieldTitle;