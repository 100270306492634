import { i18n } from '@lingui/core';
import { en, es } from "make-plural/plurals"
import { messages as enMessages } from './locales/en/messages';
import { messages as esMessages } from './locales/es/messages';

i18n.load('en', enMessages);
i18n.load('es', esMessages);
i18n.loadLocaleData("en", { plurals: en });
i18n.loadLocaleData("es", { plurals: es });
i18n.activate('es');

export default i18n;