const workflowDiagramActions = {
    setViewportHeight: (height) => ({ height }),
    setSelection: ({ selection }) => ({ selection }),
    setStepSize: ({ name, size }, state) => workflowDiagramActions.setStepAttrs({ name, data: { size } }, state),
    setStepPosition: ({ name, position: { x, y } }, state) => workflowDiagramActions.setStepAttrs({ name, data: { x, y, role: getRowPosition(y, state.roles, state.layout) } }, state),
    setStepRoleActionPermission: ({
        step: stepName, role, permission, value
    }, {steps}) => {
        const step = steps[stepName];
        if (!step) return;
        const newStep = {
            ...step,
            permissions: {
                ...(step.permissions || {}),
                [role]: {
                    ...(step.permissions?.[role] || {}),
                    [permission]: value
                }
            }
        };
        return {steps: { ...steps, [stepName]: newStep}};
    },
    setWFDetails: ({details}) => ({details}),
    setDetails: ({ name, value }, state) => {
        const newState = {
            ...state,
            [name]: value
        }
        return newState;
    },

    renameSelectedStep(newName, { selection, steps }) {
        if (selection === newName || !newName) {
            return;
        }

        newName = determineUniquePropName(newName, steps);

        return {
            selection: newName,
            steps: Object.entries(steps).reduce((_, [k, v]) => {
                k = (k === selection ? newName : k);
                v.name = k;
                _[k] = v;
                return _;
            }, {}),
        };
    },

    setParamSchema: ({ paramSchema }) => ({ paramSchema }),
    setManageParamSchema: ({ key, value, active = true }, state) => {
        const newState = {
            ...state,
        }

        const newParamSchema = { ...newState.paramSchema };
        if (active) {
            const key = determineUniquePropName(toCamelCase(value), newParamSchema)
            newParamSchema[key] = { title: value, type: 'boolean' };
        } else {
            delete newParamSchema[key];
        }

        newState.paramSchema = newParamSchema;
        return newState;
    },
    setStepAttrs: ({ name, data }, { steps, layout, roles }) => {
        const newState = {
            steps: {
                ...steps,
                ...(
                    steps[name] ?
                        { [name]: { ...steps[name], ...data } } :
                        {}
                )
            }
        };
        const newStep = newState.steps[name];
        if (newStep.role !== steps[name].role) {
            newStep.y = (roles.indexOf(newStep.role) + 0.5) * layout.swimmingLane.height;
        }

        if (newStep.transitions !== steps[name].transitions) {
            newState.arrows = Object.values(newState.steps || {}).reduce((_, stepdef) => {
                stepdef.transitions?.forEach((tx) => (
                    _.push(...determineArrows(tx.next, tx.name).map(arrow => ({
                        from: stepdef.name,
                        ...arrow,
                    })))
                ));
                return _;
            }, []);
        }

        return newState;
    },
    moveRole: ({
        roleIdx,
        targetIdx
    }, {
        roles, steps, layout: {swimmingLane}
    }) => {
        if (roleIdx === targetIdx) return;
        const newRoles = roles.slice();
        const [role] = newRoles.splice(roleIdx, 1);
        if (targetIdx > roleIdx) targetIdx -= 1;
        newRoles.splice(targetIdx, 0, role);

        const oldSwimmingLaneMap = roles.reduce((_, role, idx) => {
            _[role] = swimmingLane.height * idx; return _;
        }, {});
        const newSwimmingLaneMap = newRoles.reduce((_, role, idx) => {
            _[role] = swimmingLane.height * idx; return _;
        }, {});
        const newSteps = Object.entries(steps).reduce((_, [stepName, step]) => {
            _[stepName] = {
                ...step,
                y: step.y - oldSwimmingLaneMap[step.role] + newSwimmingLaneMap[step.role]
            };
            return _;
        }, {});

        console.log({
            roleIdx,
            targetIdx,
            old: {
                steps,
                roles,
                laneMap: oldSwimmingLaneMap
            },
            new: {
                steps: newSteps,
                roles: newRoles,
                laneMap: newSwimmingLaneMap
            }
        });

        return {
            roles: newRoles,
            steps: newSteps
        };
    },
    setRol: ({ rolName, active }, state) => {
        let arrRoles = state.roles.slice();
        let steps = state.steps;
        if (!active) {
            arrRoles = arrRoles.filter(x => x !== rolName);
            steps = Object.values(state.steps || {}).reduce((_, obj) => {
                if (obj?.role === rolName) {
                    obj.y = undefined;
                    obj.role = "";
                }
                _[obj.name] = obj;
                return _;
            }, []);
        } else {
            arrRoles.push(rolName);
        }

        const layout = {
            swimmingLane: {
                height: 200,
                width: 2400,
                gap: 30
            },
        };

        return {
            roles: arrRoles,
            steps,
            layout
        }
    },
    setSteps: ({ steps }) => ({ steps }),
    addStep(stepDef, state) {
        const key = determineUniquePropName("newStep", state.steps)
        const newStep = {
            title: "NEW STEP",
            name: key,
            ...stepDef
        };
        return {
            steps: { ...state.steps, [key]: newStep }
        }
    },
    removeStep(key, state) {
        const newStep = { ...state.steps }
        delete newStep[key];
        return {
            steps: newStep
        }
    },
    setWorkflow(workflow) {
        const layout = {
            maxWidth: 0,
            step: {
                width: 300,
                height: 50
            },
            swimmingLane: {
                height: 200,
                width: 2400,
                gap: 30
            },
        };

        const stepsList = Object.entries(workflow?.definition || {}).map(([id, stepdef], idx) => {
            const step = {
                idx,
                name: id,
                ...stepdef
            };
            const transitions = step.transitions || [];
            if (step.next) {
                transitions.push({ next: step.next, action: step.action, name: "next" });
                delete step.next;
                delete step.action;
            }
            if (step.sendBack) {
                transitions.push({ next: step.sendBack, action: step.sendBackAction, name: "sendBack" });
                delete step.sendBack;
                delete step.sendBackAction;
            }
            if (transitions.length) {
                step.transitions = transitions;
            }
            return step;
        });

        stepsList.sort((a, b) => (
            (a.endstatus | 0) - (b.endstatus | 0)
        ))

        // workflow.roles = workflow?.roles?.[0] !== "" ? [""].concat(workflow.roles) : workflow?.roles;
        const roles = workflow?.roles ? workflow.roles : Array.from(stepsList.reduce((_, step) => {
            _.add(step.role || "");
            return _;
        }, new Set()));

        const swimmingLaneRoleMap = roles.reduce((_, role, idx) => {
            _[role] = layout.swimmingLane.height * (idx + .5);
            return _;
        }, {});

        const steps = stepsList.reduce((_, step, idx) => {
            _.steps[step.name] = step;
            if (step.x === undefined) {
                step.x = idx * layout.step.width;
            }

            if (step.y === undefined) {
                step.y = swimmingLaneRoleMap[step.role || ""];
                if (step.role) {
                    step.y = swimmingLaneRoleMap[step.role];
                } else if (_.lastStep) {
                    step.y = _.lastStep.y;
                } else {
                    step.y = swimmingLaneRoleMap[""];
                }
            }

            const x2 = step.x + layout.step.width;

            if (x2 > layout.maxWidth) { layout.maxWidth = x2; }

            _.lastStep = _.steps[step.name];
            return _;
        }, {
            steps: {},
            lastStep: null
        }).steps;

        layout.swimmingLane.width = layout.maxWidth;

        const arrows = Object.values(steps || {}).reduce((_, stepdef) => {
            stepdef.transitions?.forEach((tx) => (
                _.push(...determineArrows(tx.next, tx.name).map(arrow => ({
                    from: stepdef.name,
                    ...arrow,
                })))
            ));
            return _;
        }, []);

        return {
            initialized: true,
            layout,
            steps,
            roles,
            arrows,
            workflow,
            details: {
                name: workflow.name,
                objectSchemaId: workflow.objectSchemaId,
                slug: workflow.slug,
                redirect: workflow.redirect,
                viewForm: workflow.viewForm,
            },
            name: workflow.name,
            objectSchemaId: workflow.objectSchemaId,
            paramSchema: workflow.paramSchema
        };
    },
};

function getRowPosition(y, roles, layout) {
    return roles.reduce((_, role, idx) => {
        const swimmingLaneY = idx * layout.swimmingLane.height;
        return swimmingLaneY < y ? role : _;
    }, null);
}

const toCamelCase = (str) => (
    str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '')
);

function determineArrows(comparisonRHS, action) {
    if (!comparisonRHS) return [];
    if (typeof comparisonRHS === 'string') return [{
        to: comparisonRHS,
        text: action
    }];
    if (Array.isArray(comparisonRHS)) {
        return [].concat(...comparisonRHS.map((rhs, idx) => determineArrows(rhs, `${action} [${comparisonRHSToString(comparisonRHS[idx])}]`)));
    }
    if (comparisonRHS.$if) return [
        ...determineArrows(comparisonRHS.$then, `${action} if ${comparisonRHSToString(comparisonRHS.$if)}`),
        ...determineArrows(comparisonRHS.$else, `${action} else`)
    ];
    if (comparisonRHS.$lookup && comparisonRHS.$options) return [].concat(...comparisonRHS.$options.map(opt => determineArrows(opt, `${action} lookup [${opt}]`)));
    console.log({ comparisonRHS });
    return [];
}

function comparisonRHSToString(comparisonRHS) {
    if (!comparisonRHS) return "false";
    if (typeof comparisonRHS === 'string') return comparisonRHS;
    if (Array.isArray(comparisonRHS)) {
        return `[${comparisonRHS.map(comparisonRHSToString).join(", ")}]`
    }
    if (comparisonRHS.$if) {
        return `if (${comparisonRHSToString(comparisonRHS.$if)}) then (${comparisonRHSToString(comparisonRHS.$then)})${comparisonRHS.$else ? ` else (${comparisonRHSToString(comparisonRHS.$else)})` : ''
            }`;
    }
    if (comparisonRHS.$lookup) {
        return `lookup(${comparisonRHSToString(comparisonRHS.$lookup)}${comparisonRHS.$options ? `, ${comparisonRHSToString(comparisonRHS.$options)}` : ''
            })`;
    }
    if (typeof comparisonRHSToString === 'object') {
        return `{${Object.entries(comparisonRHS).map((([k, v]) => `${k}: ${comparisonRHSToString(v)}`).join(', '))
            }}`;
    }
}

function determineUniquePropName(name, object) {
    let candidate = name;
    let i = 1;

    while (candidate in object) {
        candidate = `${name}_${i}`;
        i += 1;
    }

    return candidate;
}


export default workflowDiagramActions;