import { t } from "@lingui/macro";
// import { Trans } from '@lingui/react';
import React from 'react';
import { Redirect, useParams, useHistory } from 'react-router';
import UserApi from "../../api/UserApi";
import FormComponent from '../../components/FormComponent';
import InternalLayout from '../../components/layouts/InternalLayout';
import { FORM_UPDATE_SUBMIT_BUTTON } from '../../constants';


export const makeUserSchema = ({
    title = t`User`,
    showJediId = true,
    onlyEditUserData = false,
    hidePassword = false,
    hideVehicle = false
} = {}) => ({
    ":classNames": "smallTwoRows",
    title,
    "ui:field": "HideObjectField",
    type: "object",
    properties: {
        firstName: { title: t`First Name`, type: "string" },
        lastName: { title: t`Last Name`, type: "string" },
        position: { title: t`Position`, type: ["string", "null"] },
        accessProfileId: {
            title: t`User Type`,
            type: "number",
            lookup: {
                api: 'profiles',
                resource: t`User Types`,
                id: 'id',
                label: 'displayName'
            },
            "ui:field": "LookupFormField",
            "ui:readonly": onlyEditUserData,
        },
        email: {
            title: "Email", type: "string",
            "ui:readonly": onlyEditUserData
        },
        phone: { title: t`Phone`, type: ["string", "null"] },
        extension: { title: t`Extension`, type: ["string", "null"] },
        ...(hidePassword ? {} : {
            password: {
                title: t`Password`, type: "string",
                ":classNames": "from-col-1",
                "ui:widget": "password"
            },
            passwordConfirm: {
                title: t`Confirm Password`, type: "string",
                "ui:widget": "password",
                "akc:validate": "$isTruthy(password) and password != passwordConfirm ? 'es diferente.' : null"
            },
        }),
        signature: {
            title: t`Signature`, type: ["string", "null"],
            "ui:field": "SignatureField"
        },
        ...(hideVehicle ? {} : {
            vehicleId: {
                ":classNames": "grid-3-columns",
                "title": "Vehículo",
                "ui:field": "Autocomplete",
                "ui:showIf": {
                    "scope": ".",
                    "expr": "organizationalUnitRoles[role[name='Conductor']]"
                },
                "lookup": {
                    "defaultValueExpr": {
                        // "expr": "({'value': $formData.data.id, 'label': vehicle.data.licensePlate & ' ' & vehicle.data.make & ' ' & vehicle.data.model})",
                        "expr": [
                            "(",
                            "$vehicleId:=$formData;",
                            "$vehicleLabel:=vehicle.data.licensePlate & ' ' & vehicle.data.make & ' ' & vehicle.data.model;",
                            "($vehicleId ? ({'value': $vehicleId, 'label': $vehicleLabel}) : null)",
                            ")",
                        ]
                    },
                    "resource": "Agencia",
                    "entity": "Vehicle",
                    "id": "id",
                    "label": {
                        "expr": "metadata.licensePlate & ' ' & metadata.make & ' ' & metadata.model",
                    },
                    "params": {
                        "all": true
                    },
                    "searchParam": "metadata-like-licensePlate"
                },
                "type": ["number", "null"]
            },
        }),
        organizationalUnitRoles: {
            title: " ",
            description: t`Organizational Units and Roles`,
            ":classNames": "from-col-1 to-col-3",
            type: "array",
            "ui:readonly": onlyEditUserData,
            items: {
                type: "object",
                "ui:addable": !onlyEditUserData,
                "ui:orderable": !onlyEditUserData,
                "ui:removable": !onlyEditUserData,
                properties: {
                    organizationalUnitId: {
                        title: t`Organizational Unit`,
                        type: "number",
                        "ui:field": "LookupFormField",
                        "ui:readonly": onlyEditUserData,
                        ":classNames": "from-col-1-size-1",
                        lookup: {
                            api: 'organizationalunits',
                            resource: t`Organizational Units`,
                            "parentId": "parentId",
                            "collapsable": true,
                            "collapseLevel": 1,
                            id: 'id',
                            label: 'name'
                        },
                    },
                    roleId: {
                        title: t`Role`,
                        lookup: {
                            api: 'roles',
                            resource: t`Roles`,
                            id: 'id',
                            label: 'name'
                        },
                        items: { type: "number" },
                        "ui:field": "LookupFormField",
                        "ui:readonly": onlyEditUserData,
                        "ui:onSelect": {
                            "expr": [
                                "(",
                                "$path:= ($fieldPath ~>$pathJoin('..role')) & '.name';",
                                "$set({$path: $object.name});",
                                ")"
                            ],
                        }
                    },
                    canWorkAs: {
                        "title": t`Can Work`,
                        "ui:cellClassName": "text-center align-middle",
                        "type": "boolean"
                    },
                    canBeAssigned: {
                        "title": t`Can be Assigned`,
                        "ui:cellClassName": "text-center align-middle",
                        "type": "boolean"
                    },
                    canAssign: {
                        "title": t`Can Assign`,
                        "ui:cellClassName": "text-center align-middle",
                        "type": "boolean"
                    },
                    mainRole: {
                        "title": t`Main Role`,
                        "ui:cellClassName": "text-center align-middle",
                        "type": "boolean"
                    }
                }
            }
        },
        notifyDesktop: {
            title: t`Desktop Notifications`,
            type: "boolean",
            ":classNames": "from-col-1",
        },
        notifyEmail: {
            title: t`Email Notifications`,
            type: "boolean",
            ":classNames": "from-col-1",
        },
        isOnLeave: {
            title: t`Out of Office`,
            type: "boolean",
            ":classNames": "from-col-1",
        },
        ...(onlyEditUserData ? {} : {
            isActive: {
                title: t`Active`,
                type: "boolean",
                ":classNames": "from-col-2",
            }
        })
    },
    required: [
        "firstName",
        "lastName",
        "email",
        "accessProfileId"
    ],
    dependencies: {
        password: ["passwordConfirm"]
    }
});

export const EditUserForm = FormComponent({
    displayName: 'EditUser',
    withoutNavigation: true,
    schema: makeUserSchema(),
    uiSchema: {},
    parseProps: () => ({
        params: useParams(),
        history: useHistory()
    }),
    loadData: ({ params: { id } }) => UserApi.getUser({ id }),
    async onSubmit({ formData }, { props: { params: { id } } }) {
        await UserApi.editUser({ id, ...formData });
    },
    submitButtons: {
        ...FORM_UPDATE_SUBMIT_BUTTON,
        impersonate: {
            text: t`Impersonate`, className: "btn-secondary", "onBeforeSubmit": async (submitEvent) => {
                submitEvent.preventDefault();

                const {
                    formContext: { auth },
                    scope: { props: {
                        history,
                        params: { id: userId }
                    } }
                } = submitEvent;

                await auth.impersonate({ userId });
                history.push("/");
            },
            "ui:showIf": "$formContext.auth.user.permissions.ImpersonatePeersOrSubordinates",
            ignoreValidation: true
        },
    },
    renderFormSubmitted: () => (
        <Redirect to="../system-settings/users" />
    )
});

export default function () {
    return <InternalLayout><EditUserForm /></InternalLayout>;
};
