import React, { useContext,useMemo, useState, useEffect } from "react";
import { Button, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { AuthContext } from "../context/AuthContext";
import UWEEntityApi from '../api/UWEEntityApi';
import Loader from '../components/Loader';


function LicenseGeneration(props){

    const {currentEntityId} = props;

    const [hasLicense, setHasLicense] = useState(false);
    const [licenseFile, setLicenseFile] = useState('')
    const [licenseUrl, setLicenseUrl] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [modalLicense, setModalLicense] = useState(false);
    function modalLicenseToggle(transition = false) {
        setModalLicense(transition)
    };

    const pollForLicense = async (attempts = 1) => {
        return new Promise((resolve, reject) => {
            const attemptFetch = async (remainingAttempts) => {
                if (remainingAttempts <= 0) {
                    reject(new Error("License file not found after several attempts."));
                    return;
                }
                try {
                    const response = await UWEEntityApi.fetchLicense(currentEntityId);
                    if (response) {
                        setHasLicense(true);
                        setLicenseFile(response);
                        setLicenseUrl(response.url);
                        resolve();
                    } else {
                        setTimeout(() => attemptFetch(remainingAttempts - 1), 5000);
                    }
                } catch (error) {
                    setTimeout(() => attemptFetch(remainingAttempts - 1), 5000);
                }
            };

            attemptFetch(attempts);
        });
    };

    useEffect(() => {
        pollForLicense();
    }, [currentEntityId]);

    const generateLicense = async () => {
        setIsGenerating(true);
        try {
            await UWEEntityApi.generateLicenseImage(currentEntityId);
            await pollForLicense(10); // Refresh license check
        } catch (error) {
            console.error('Error generating license:', error);
        } finally {
            setIsGenerating(false);
        }
    };

    async function handleLicenseAction() {
        if (hasLicense && licenseUrl) {
            modalLicenseToggle(true);
        } else {
            await generateLicense();
        }
    }
    const extension = ((licenseFile && licenseFile.name) || '').split('.').pop();
    const licenseButton = (
        <>
            <Button
            className="btn btn-primary float-right"
            style={{ marginRight: "15px" }}
            onClick={handleLicenseAction}
            disabled={isGenerating}
            >
                {hasLicense ? "Licencia" : "Generar Licencia"}
                {isGenerating && <Loader sm/>}
            </Button>
            <Modal isOpen={modalLicense}
                toggle={() => modalLicenseToggle()}
                className="modal-primary"
                size='xl'
                backdrop
            >
                <ModalHeader toggle={() => modalLicenseToggle()} >
                    Vista previa de Archivo
                </ModalHeader>
                <ModalBody style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    {
                        ["png","jpeg", "PNG", "JPEG", "jpg", "JPG"].includes(extension) ?
                        <img
                        src={licenseUrl}
                        alt="File"
                        width="75%"
                        style={{alignSelf: 'center'}}
                        /> :
                        <iframe
                        title="Files"
                        src={`https://docs.google.com/gview?url=${licenseUrl}&embedded=true`}
                        height="500"
                        width="100%"
                        frameBorder="0"
                        />
                    }
                </ModalBody>
                <ModalFooter>
                    <a className="btn btn-primary" href={licenseUrl} target="_blank" rel="noreferrer" >Descargar</a>
                    <Button onClick={() => modalLicenseToggle()} color="secondary">Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
    return licenseButton;
}

export default LicenseGeneration;