import { useCallback, useMemo, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';


function useSearchParams(params){
    const { search, pathname } = useLocation();
    const history = useHistory();
    const parsed = useMemo(() => queryString.parse(search || ""), [search]);

    const historyRef = useRef();
    historyRef.current = history;
    const parsedRef = useRef();
    parsedRef.current = parsed;

    const setQueryString = useCallback((pathname, qsparams) => {
        historyRef.current.push(`${pathname}?${queryString.stringify(qsparams)}`);
    }, [historyRef]);
    
    const setParam = useCallback((param, value) => {
        parsedRef.current = Object.entries({...(parsedRef.current || {}), [param]: value}).reduce((_, [k, v]) => {
            if (v !== undefined) _[k] = v;
            return _;
        }, {});
        setQueryString(pathname, parsedRef.current);
    }, [pathname]);

    const paramList = useMemo(() => Object.keys(params), [Object.keys(params).join(',')])

    const setters = useMemo(() => [
        ...paramList.map(param => (
            (value) => setParam(param, value)
        )),
        (newparams) => {
            parsedRef.current = {...newparams};
            setQueryString(pathname, parsedRef.current);
        }
    ], [pathname, setParam, paramList]);

    return [
        ...paramList.map((param, idx) => [
            parsedRef.current[param] || params[param],
            setters[idx]
        ]),
        [
            parsedRef.current,
            setters[setters.length - 1]
        ]
    ];
}


export default useSearchParams;