function callWith(args, fn){
    return fn(...args);
} 

const TYPES = {
    'number': 'número',
    'object': 'objeto',
    'array': 'lista',
    'string': 'texto'
}

const $ = {
    n                     : ({params: {limit: n}}) => `${n}`,
    c                     : ({params: {comparison, limit}}) => `${comparison} ${limit}`,
    plural1               : ({params: {limit: n}}) => `${ (n | 0) !== 1 ? 's' : ''}`,
    plural2               : ({params: {limit: n}}) => `${ (n | 0) !== 1 ? 'es' : ''}`,
    _defaultMessage       : (e) => `debe pasar la validación de palabra clave "${e.keyword}"`,
    "false schema"        : ()  => `el esquema és falso`,
    additionalItems       : (e) => callWith([$.n(e)], (n) => `no debe tener más de ${n} elemento${$.plural1(e)}`),
    additionalProperties  : ()  => `no debe tener propiedades adicionales`,
    anyOf                 : ()  => `debe coincidir con algún esquema en "anyOf"`,
    dependencies          : (e) => callWith([$.n(e)], (n) => `debe contener la${$.plural1(e)} propiedad${$.plural2(e)} ${e.params.deps} cuando la propiedad ${e.params.property} se encuentra presente`),
    enum                  : ()  => `deber ser igual a uno de los valores predefinidos`,
    format                : (e) => `debe coincidir con el formato "${e.params.format}"`,
    maximum               : (e) => callWith([$.c(e)], cond => `debe ser ${cond}`),
    minimum               : (e) => callWith([$.c(e)], cond => `debe ser ${cond}`),
    maxItems              : (e) => callWith([$.n(e)], n => `no debe contener más de ${n} elemento${$.plural1(e)}`),
    minItems              : (e) => callWith([$.n(e)], n => `no debe contener menos de ${n} elemento${$.plural1(e)}`),
    maxLength             : (e) => callWith([$.n(e)], n => `no debe contener más de ${n} caracter${$.plural2(e)}`),
    minLength             : (e) => callWith([$.n(e)], n => `no debe contener menos de ${n} caracter${$.plural2(e)}`),
    maxProperties         : (e) => callWith([$.n(e)], n => `no debe contener más de ${n} propiedad${$.plural2(e)}`),
    minProperties         : (e) => callWith([$.n(e)], n => `no debe contener menos de ${n} propiedad${$.plural2(e)}`),
    multipleOf            : (e) => `debe ser múltiplo de ${e.params.multipleOf}`,
    not                   : ()  => `no debe ser válido según el esquema en "not"`,
    oneOf                 : ()  => `debe coincidir con un solo esquema en "oneOf"`,
    pattern               : (e) => `debe coincidir con el patron "${e.params.pattern}"`,
    required              : (e) => `es requerido`,
    type                  : (e) => `debe ser ${TYPES[e.params.type] || e.params.type}`,
    unevaluatedProperties : ()  => `no debe tener propiedades sin evaluar`,
    unevaluatedItems      : (e) => callWith([$.n(e)], (n) => `no debe tener mas de ${n} item${$.plural1(e)}`),
    uniqueItems           : (e) => `no debe contener elementos duplicados, (los elementos ## ${e.params.j} y ${e.params.i} son idénticos)`,
    propertyNames         : ()  => `la propiedad no és válida`,
    patternRequired       : (e) => `la propiedad debe coincidir con el patrón "${e.params.missingPattern}"`,
    const                 : ()  => `debe ser igual a la constante`,
    contains              : ()  => `debe contener un elemento válido`,
    formatMaximum         : (e) => callWith([$.c(e)], (cond) => `debe ser ${cond}`),
    formatMinimum         : (e) => callWith([$.c(e)], (cond) => `debe ser ${cond}`),
    if                    : (e) => `debe corresponderse con el esquema "${e.params.failingKeyword}"`,
    discriminator         : (e) => `el valor en "${e.params.tag}" debe ser texto`,
    mapping               : (e) => `el valor de "${e.params.tag}" debe estar en "oneOf"`,
};

export default $;