import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FormGroup, Input, InputGroup, InputGroupText } from "reactstrap";
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";
import { useFormatter } from '../../util/applyFormatting';
import { useJnx } from "../../util/jnx";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import getPathFromId from "../../util/getPathFromId";


export default function TextInput(props) {
    const {
        id, required, disabled, readonly, autofocus,
        uiSchema: { 
            'ui:staticText': staticText, 
            'ui:classNames': classNames, 
            'ui:prefix': prefix, 'ui:sufix': sufix, 
            'ui:placeHolder': placeholder, 
            'akc:disableSpaces': disableSpaces, 
            'ui:readonlyIf': uiReadonlyIf,
            "akc:integerOnly": integerOnly,
            "akc:numbersOnly": numbersOnly,
            "akc:lettersOnly": lettersOnly,
            "akc:noSpecialChar": noSpecialChar,
        },
        schema: { minLength, maxLength },
        formData,
        formContext,
        formContext: {
            setFormDataValues,
            sideChannel
        },
    } = props;

    const { text, onChange } = useTextInputHooks(props);

    const defaultValue = "";
    const dataValue = formData || defaultValue;
    const rootFormData = useSideChannelSubscription(sideChannel, 0);
    
    const dataPath = useMemo(() => getPathFromId(id), [id]);
    const readonlyIfJnx = useJnx(uiReadonlyIf);
    const readonlyif = useMemo(() => (
        readonlyIfJnx && readonlyIfJnx.eval(rootFormData || {}, dataPath, {
            root: rootFormData,
            formContext,
        })
    ), [dataValue, rootFormData, formContext]);


    const isDisabled = readonly || readonlyif || disabled;
    return (<FormGroup>{staticText ? (
        <div className={classNames || ''}>{text}</div>
    ) : wrapInput(
        prefix,
        <Input
            disabled={isDisabled}
            type="text"
            placeholder={`${placeholder || ''}`}
            className={`custom ${classNames || ''}`}
            value={text}
            minLength={minLength || ''}
            maxLength={maxLength || ''}
            required={required}
            disablespaces={disableSpaces}
            integerOnly={integerOnly}
            numbersOnly={numbersOnly}
            lettersOnly={lettersOnly}
            noSpecialChar={noSpecialChar}
            onChange={onChange}
            readOnly={readonly}
            readonlyif={readonlyif ? 1:0}
            autoFocus={autofocus}
            id={id}
            data-cy={id}
        />,
        sufix
    )}</FormGroup>);
}

function wrapInput(prefix, input, sufix) {

    if (!prefix && !sufix)
        return input;

    return (
        <InputGroup>
            {prefix ? <InputGroupText>{prefix}</InputGroupText> : null}
            {input}
            {sufix ? <InputGroupText>{sufix}</InputGroupText> : null}
        </InputGroup>);
}


function useTextInputHooks({
    schema: { format },
    value,
    onChange: onChangeForm,
}) {

    const [current, setCurrent] = useState();
    const formatter = useFormatter(format);

    useDebouncedEffect(() => {
        if (current && current.value !== value) {
            onChangeForm(current.value);
        }
    }, [current?.value], 450);

    const onChange = useCallback(({ target: { value: text } }) => setCurrent({
        value: formatter.parse(text),
        text
    }), [formatter]);

    useEffect(() => {
        setCurrent({
            value,
            text: formatter.apply(value || "")
        });
    }, [value]);

    return { text: current?.text || "", onChange };
}