import { t, Trans } from "@lingui/macro";
import React, { useMemo, useContext } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardGroup
} from 'reactstrap';
import Plot from 'react-plotly.js';
import { AuthContext } from '../../context/AuthContext';
import Loader from '../Loader';
import useResourceLoader from '../../util/useResourceLoader';
import UWEEntityApi from '../../api/UWEEntityApi';

function SummaryChart (props) {
    const { element } = props;
    const auth = useContext(AuthContext);

    const {
        user: {
            user: { role: { extras } = {} } = {},
        } = {}
    } = auth;

    const lookup = useMemo(() => element.lookup || ({
        entity: element.entityType,
        api: !element.entityType && element.resource ? `api:${element.resource}` : null,
        params: element.lookupParams
    }), [element?.lookup, element]);    

    const [response, loading, error] = useResourceLoader(async () => {
        const resource = lookup.entity ? `uwe-entities/${lookup.entity}/total-count` : lookup.api.split(':')[1];
        if (!resource) return null;
        const data = await UWEEntityApi.getWorkTray({ resource });
        return Array.isArray(data) ? {           
            items: data
        } : data;
    
    }, [lookup]);
    
    const {
        items: data = [],
    } = response || {};
    
    const lastStep = element.lastStep?.toLowerCase().trim().replace(/\s/g, '');
    const completedCount = data?.find((elem) => elem.step === lastStep)?.count || 0;
    const dataSteps = data?.filter((elem) => elem.step !== lastStep && elem.type !== "end") || [];

    const dataChart = data
        ? [
            {
                x: dataSteps.map(({ title }) => title),                
                y: dataSteps.map(({ count }) => count),
                type: "bar",
                text: dataSteps.map(({ count }) => count),
                textposition: dataSteps.map(({ count }) => (count < 10 ? "outside" : "auto")),
                marker: {
                    width: 0.5,
                    color: "#15416F",
                }
            },
        ] : [];

        const wrapTitle = (title) => {
            const palabras = title.split(' ');
            const titulo = [];
            let actual = '';
            for (let i = 0; i < palabras.length; i += 1) {
              const palabra = palabras[i];
              
              if (`${actual}${palabra}`.length <= 10) {
                actual += `${palabra} `;
              }
              else if (i > 0) {
                titulo.push(actual.trim());
                actual = `${palabra} `;
              }
              else {
                titulo.push(palabra.trim());
              }
            }
            
            if (actual.trim() !== '') {
              titulo.push(actual.trim());
            }
            return titulo.join('<br>');
          };

    const layoutChart = {
        title: element.chartTitle,
        xaxis: { 
            title: "", 
            tickangle: 0, 
            automargin: true, 
            tickmode: 'array',  
            autowrap: true, 
            tickvals: dataSteps.map((_, index) => index),        
            ticktext: dataSteps.map(({ title }) => wrapTitle(title))  
            // ticktext: dataSteps.map(({ title }) => title.replace(/ - /,'- ').split(' ').join('<br>'))                    
        },       
        yaxis: { title: "Total", range: [0, Math.max(...dataSteps.map(({ count }) => count)+2)] },
    }; 
   
      
      

    return (
        <CardGroup>
            <Card className="inbox-card primary no-bottom-gap">
                <CardHeader>
                    <div className="title">{element.title}</div>
                </CardHeader>
                {loading ? (    
                        <Loader centered><Trans>Loading {element.title}</Trans></Loader>  
                ):(   
                    <>
                     <Card>
                        <div className="flex">
                            <div className = "chart-border-right">
                            <Plot data={dataChart} layout={layoutChart} style={{ height: '400px' }} config={{displayModeBar: false,responsive: true}}/>
                            </div>
                            <div className = "chart-summary-completed">
                                <CardBody className="card-body">
                                    <div className="chart-total total" >{completedCount}</div>
                                    <div className="chart-total">Solicitudes completadas</div>
                                </CardBody>
                            </div>
                        </div> 
                     </Card>
                    </>
                )}
            </Card>
        </CardGroup>
    )
}
SummaryChart.rootSchema = {
    "definitions": {
        "mapFieldField": {
            "type": ["string", "object"],
            "ui:field": "JsonField",
            "showTree": true,
        },
    },
    "properties": {
        "title": {
            "title": "Title",
            "type": "string",
            "map:field": "title"
        },
        "chartTitle": {
            "title": "Chart Title",
            "type": "string",
            "map:field": "chartTitle"
        },
        "lastStep": {
            "title": "Last Step",
            "type": "string",
            "map:field": "lastStep"
        },
        "entityType": {
            "title": "Entity Type",
            "type": "string",
            "map:field": "entityType",
            "ui:field": "LookupFormField",
            "lookup": {
                "resource": "Entity Types",
                "api": "api:manage/objectschemas",
                "params": { "all": true },
                "options": { "useCache": true },
                "jnx": "data.items",
                "id": "name",
                "label": "name"
            }
        },             
    }
}
export default SummaryChart; 