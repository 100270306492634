import React, { useEffect, useRef, useState, useMemo } from "react";
import {
    FormGroup,
    Button
} from "reactstrap";
import { t, Trans } from "@lingui/macro"

import useResourceLoader from '../../util/useResourceLoader';
import Notification from "../Notification";
import Loader from "../Loader";
import FileApi from "../../api/FileApi";
import StaticFileApi from "../../api/StaticFileApi";
import FileInput from "../FileInput";
import Jnx, { parseJnxExpr, useJnx } from "../../util/jnx";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import getPathFromId from "../../util/getPathFromId";


function FileField(props) {
    const {
        formData,
        formContext,
        formContext: {
            setFormDataValues,
            sideChannel
        },
        name,
        disabled,
        readonly: propReadonly,
        schema: {
            title,
            buttonText,
            emptyText,
            fileUrlField = "url",
            fileNameField = "name",
            resource = t`File`,
            type,  // has to be number (the file id)
            "const": constValue,
            staticFile,
        },
        idSchema: { '$id': fieldId },
        uiSchema: {
            'ui:titleAsLink': titleAsLink,
            'ui:hideOnEmpty': hideOnEmpty,
            'ui:readonly': uiReadonly,
            'ui:hideAttr': uiHideAttr,
            'akc:requiredIfVisible': requiredIfVisible,
            'akc:requiredIf': requiredIfExpr,
            'ui:extensions': uiExtensions = "*"
        },
        // title,
        required,
        onChange: propOnChange
    } = props;


    const labelId = `${fieldId}-label`;
    const postArgs = ((formContext || {}).fileField || {}).postArgs;

    const useFileId = type === "number";
    const useStaticFile = !!staticFile;
    const value = formData || constValue || staticFile;
    const defaultValue = "";
    const dataValue = formData || defaultValue;
    const rootFormData = useSideChannelSubscription(sideChannel, 0);

    const readonly = uiReadonly || !!constValue || useStaticFile || propReadonly;
    const dataPath = useMemo(() => getPathFromId(fieldId), [fieldId]);
    // console.log(requiredIfExpr)
    // console.log(REQUIRED_IF_JNX(requiredIfExpr))
    const requiredIfJnx = useJnx(requiredIfExpr);
    const requiredIf = useMemo(() => (
        requiredIfJnx && requiredIfJnx.eval(rootFormData || {}, dataPath, {
            root: rootFormData,
            formContext,
        })
    ), [dataValue, rootFormData, formContext]);
    const requiredFile = !!required || !!requiredIfVisible || !!requiredIf

    const [file, loadingFile, errorLoadingFile] = useResourceLoader(() => {
        if (useFileId) return value ? FileApi.getFile({ fileId: value }) : {};
        if (useStaticFile) return StaticFileApi.getFile({ key: staticFile });
        if (!!value && typeof value === "object") {
            if (value.url && value.name) return value;
            return { url: value[fileUrlField], name: value[fileNameField] };
        } else {
            return { url: value, name: (value || '').split('/').pop().split('?')[0] };
        }
    }, [useFileId, value]);

    const [uploadingFile, setUploadingFile] = useState();
    const [errorUploadingFile, setErrorUploadingFile] = useState();
    const error = (
        errorLoadingFile ||
        errorUploadingFile ||
        ((!loadingFile && (
            !file || (readonly && !file.url)
        )) ? { message: emptyText || t`No ${resource} to show`, alert: 'secondary' } : null)
    );

    async function onChange(file) {
        if (file) {
            const extension = file.name.indexOf('.') >= 0 ? `.${file.name.split('.').pop()}` : '';

            try {
                setUploadingFile(true);
                const result = await FileApi.uploadFile({ ...(postArgs || {}), file, filename: `${name}${extension}` });
                propOnChange(useFileId ? result.id : result.url);
            } catch (e) {
                setErrorUploadingFile(e);
            }

            setUploadingFile(false);
        }
    }

    async function onRemove() {
        if (!file) return;
        propOnChange("");
        if (useFileId) return value ? FileApi.deleteFile({ fileId: value }) : {};
    }

    if (hideOnEmpty && readonly && !value) {
        return null;
    }

    if (uiHideAttr && readonly) {
        return null;
    }

    return (
        <FormGroup className="file-field" disabled={readonly}>
            {titleAsLink && title && readonly && file && file.url ? (
                <Button tag="a" href={file.url} target="_blank">{title}</Button>
            ) : (<>
                {(title && title !== " ") ? (<label className="control-label" htmlFor={labelId}>
                    {title}{requiredFile ? <span className="required">*</span> : null}
                </label>) : null}
                {uploadingFile ? (<div>
                    <Loader><Trans>Uploading {resource}</Trans></Loader>
                </div>) : (loadingFile ? (<div>
                    <Loader><Trans>Loading {resource}</Trans></Loader>
                </div>) : (<>
                    {error ? (<Notification error={error} />) : null}
                    <FileInput
                        file={file}
                        disabled={disabled}
                        viewButtonText={buttonText}
                        onFileSelected={onChange}
                        onFileRemoved={onRemove}
                        resource={resource}
                        accept={uiExtensions || "*"}
                        readonly={readonly}
                        required={requiredFile}
                    />
                </>))}
            </>)}
        </FormGroup>
    );
}


export default FileField;