import { useMemo, useReducer } from 'react';

function useActionReducer(actions, initialState){
    const reducer = useMemo(() => (state, {action, payload}) => {
        let newState;
        if (actions[action]) {
            newState = actions[action](payload, state);
        } else {
            console.warn(`unknown action sent to reducer: ${action}`);
        }

        if (!newState ||
            newState === state ||
            Object.entries(newState).every(([k, v]) => v === state[k])
        ) {
            return state;
        }

        return {...state, ...newState};
    }, [actions]);

    const [state, dispatch] = useReducer(reducer, initialState);

    const dispatchAction = useMemo(() => Object.keys(actions).reduce((_, action) => {
        _[action] = (payload) => dispatch({action, payload});
        return _;
    }, {}), [actions]);

    return [state, dispatchAction];
}


export default useActionReducer;