import React, { useMemo } from 'react';


const PATTERN = { type: "select", options: { $notEmpty: true }, multiple: { $isEmpty: true } };


function SelectField({ id: idx, fieldProps: prop, value, onChange, scope }) {

    const options = useMemo(() => (prop.optionsFromScope ? (scope && scope[prop.optionsFromScope]) : prop.options) || {}, [scope, prop]);

    const {showValueIfNotInOptions, valueTextFn} = prop;
    const hasValue = useMemo(() => options.some(item => item.value === value), [options, value]);
    const valueText = useMemo(() => (
        valueTextFn ? valueTextFn(value) : `${value}`
    ), [value, valueTextFn]);

    return (<>
        <label className="title">{prop.label}</label>
        <select
            name={prop.property}
            value={value || ""}
            title={valueText}
            className='form-control'
            onChange={({ target: { value } }) => onChange(value)}
        >
            {!hasValue && showValueIfNotInOptions ? (
                <option value={value}>{valueText}</option>
            ) : null}
            <option value="">----</option>
            {Object.values(options).map((item, idx) => (
                <option key={idx} value={item.value}>{item.label}</option>
            ))}
        </select>
    </>);
}


SelectField.pattern = PATTERN;


export default SelectField;