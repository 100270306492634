import React, { useEffect, useMemo, useRef, useState } from "react";
import {isRequired, getAvailableKey} from "./util";


function ArrayObjectRow(props) {
    const { layout } = props;
    const {
        childFields,
        uiSchema,
        errorSchema,
        idPrefix, idSchema, idSeparator,
        formContext, formData,
        wasPropertyKeyModified,
        onKeyChange,
        onBlur,
        onFocus,
        registry,
        disabled,
        readonly,
        hideError,
        show,
        onDropPropertyClick,
        SchemaField,
        schema,
        onPropertyChange
    } = useArrayObjectRowHooks(props);

    return (<>{Object.entries(childFields).map(([name, fieldDef]) => show[name] ? layout.wrapItemField(
        {name, uiSchema},
        <SchemaField
            name={name}
            required={isRequired(name, schema)}
            schema={fieldDef}
            uiSchema={uiSchema[name]}
            errorSchema={errorSchema[name]}
            idSchema={idSchema[name]}
            idPrefix={idPrefix}
            formContext={formContext}
            idSeparator={idSeparator}
            formData={(formData || {})[name]}
            wasPropertyKeyModified={wasPropertyKeyModified}
            onKeyChange={(value, errorSchema) => onKeyChange(name, value, errorSchema)}
            onChange={(value, errorSchema) => onPropertyChange(name, value, errorSchema)}
            onBlur={onBlur}
            onFocus={onFocus}
            registry={registry}
            disabled={disabled}
            readonly={readonly}
            hideError={hideError}
            onDropPropertyClick={onDropPropertyClick}
        />
    ) : layout.makeEmptyItemField())}</>);
}

function useArrayObjectRowHooks(props){
    const {
        formContext,
        idPrefix, idSeparator,
        registry,
        disabled, readonly: readonlyProp, isReadOnly,
        hideError, formData,
        onBlur, onFocus,
        onChange,
        uiSchema, errorSchema, idSchema, schema,
        rowIdx,
        showColumnsIf,
    } = props;

    const readonly = readonlyProp || isReadOnly;
    const { properties: childFields } = schema;
    const { fields: { SchemaField } } = registry;

    const [wasPropertyKeyModified, setWasPropertyKeyModified] = useState();

    const formDataRef = useRef();
    formDataRef.current = formData;

    function onKeyChange(oldValue, value, errorSchema) {
        if (oldValue === value) {
            return;
        }

        value = getAvailableKey(value, formData);
        const _formData = formDataRef.current || {};
        const newKeys = { [oldValue]: value };
        const renamed = Object.keys(_formData).reduce((_, key) => {
            _[newKeys[key] || key] = _formData[key];
            return _;
        }, {});

        setWasPropertyKeyModified(true);

        onChange(
            renamed,
            errorSchema &&
            props.errorSchema &&
            { ...props.errorSchema, [value]: errorSchema }
        );
    }

    function onDropPropertyClick(key) {
        return (event) => {
            event.preventDefault();
            const copiedFormData = { ...formDataRef.current };
            delete copiedFormData[key];
            onChange(copiedFormData);
        };
    }

    function onPropertyChange(name, value, propErrorSchema) {
        const newFormData = { ...formDataRef.current };
        newFormData[name] = value;
        onChange(
            newFormData,
            propErrorSchema && errorSchema &&
            { ...errorSchema, [name]: propErrorSchema }
        );
    }

    const show = useMemo(() => Object.keys(childFields).reduce((_, name) => {
        const showIf = showColumnsIf[name];
        _[name] = !showIf || showIf.eval(formData, name);
        return _;
    }, [showColumnsIf, childFields, formData]));

    const [indexFieldName, indexField] = useMemo(() => Object.entries(childFields).filter(
        ([name, fieldDef]) => fieldDef.isRowIndex)[0] || [null, null],
        [childFields]
    );
    const indexFieldValue = (formData || {})[indexFieldName];

    useEffect(() => {
        const rowIdxLabel = rowIdx + 1
        if (indexField && rowIdxLabel !== indexFieldValue) {
            onPropertyChange(indexFieldName, rowIdxLabel, errorSchema)
        }
    }, [indexFieldName, indexField, rowIdx, indexFieldValue]);

    return {
        childFields,
        uiSchema,
        errorSchema,
        idPrefix, idSchema, idSeparator,
        formContext, formData,
        wasPropertyKeyModified,
        onKeyChange,
        onBlur,
        onFocus,
        registry,
        disabled,
        readonly,
        hideError,
        show,
        onDropPropertyClick,
        SchemaField,
        schema,
        onPropertyChange
    };
}

export default ArrayObjectRow;