import React from 'react';
import { Logo } from "../_general/Logo";


const Loader = ({big}) => {
    return (
        <div className={`uweentity-loader ${big ? 'big' : ''}`}>
            <div className="spinner">
                <Logo style={{transform: 'translate(-20%, -20%)'}} />
            </div>
        </div>
    )
}

export default Loader;