import React, { useMemo, useState } from 'react';
import { Button as RSButton } from "reactstrap";
import { useJnx } from '../../../util/jnx';
import { useOpenModal } from '../../FormModal';
import Loader from '../../Loader';

function AdditionalButton(props) {
    const {
        formContext,
        path,
        rootFormData,
        value: {
            'ui:onClick': onClickExpr,
            title,
            ':classNames': className,
            color,
            tooltip,
            icon,
            loaderText,
            'ui:showIf': showIfExpr
        }
    } = props;

    const {
        setFormDataValues,
        bindings: fcBindings,
    } = formContext;

    const openModal = useOpenModal();
    const [loading, setLoading] = useState();

    const bindings = useMemo(() => ({
        ...fcBindings,
        openModal,
        rootFormData,
        formContext,
    }), [openModal, rootFormData, fcBindings, formContext]);

    const functionBinds = useMemo(() => ({
        set: setFormDataValues,
        log: (...args) => console.log("jsonata console log\n", ...args)
    }), [setFormDataValues]);

    const onClick = useJnx(onClickExpr, {
        fn: async ([e], onClickJnx) => {
            e.preventDefault();
            if (onClickJnx) {
                await onClickJnx.evalAsync(rootFormData, path, bindings);
            }
        },
        functionBinds
    }, [openModal, path, rootFormData, bindings, functionBinds]);

    const showIfJnx = useJnx(showIfExpr);
    const show = useMemo(() => showIfJnx ? showIfJnx.eval(rootFormData, path, bindings) : true, [showIfJnx, rootFormData, path, bindings]);

    return (<>
        {show &&
            <RSButton
                className={`${className || ''}`}
                color={color}
                onClick={onClick}
                title={tooltip || undefined}
            >
                {loading ? <Loader sm>{loaderText}</Loader> : (<>
                    {icon ? <i className={icon} /> : null}
                    {icon && title ? " " : null}
                    {title || null}
                </>)}
            </RSButton>
        }</>);
}


export default AdditionalButton;