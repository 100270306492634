import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';

export const ManageFaqApi = {
    async getFaqs() {
        return (await checkErrors(await fetchApi.api.get(`manage/faq/list`))).data;
    },
    async getFaq({ id }) {
        return (await checkErrors(await fetchApi.api.get(`manage/faq/${id}`))).data;
    },
    async createFaq(data) {
        return checkErrors(await fetchApi.api.post('manage/faq/new', { data }));
    },
    async updateFaq(id, data) {
        console.log("eee", data)
        return checkErrors(await fetchApi.api.post(`manage/faq/${id}`, { data }));
    },
    async deleteFaq({ id }) {
        return checkErrors(await fetchApi.api.delete(`manage/faq/${id}`));
    }
};

export default ManageFaqApi;
