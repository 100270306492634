import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';

export const IntegrationsApi = {
    async createIntegration(data) {
        return checkErrors(await fetchApi.api.post('manage/integrations/', { data }));
    },

    async searchAppIntegration({ offset, size, sortBy, ...filters }) {
        return checkErrors(await fetchApi.api.get('manage/integrations', {
            query: { offset, size, sortBy, ...filters }
        }));
    },

    async getAppIntegration({ id }) {
        return checkErrors(await fetchApi.api.get(`manage/integrations/${id}`));
    },

    async updateAppIntegration({ id, ...data }) {
        return checkErrors(await fetchApi.api.post(`manage/integrations/${id}`, { data }));
    },

    async GetOperationUrl(path, query) {
        const [integration, operation, ...args] = path.split('/');
        const slug = args.join('/');
        return (await checkErrors(await fetchApi.api.get(`integrations/${integration}/get-url/${operation}/${slug}`, {
            query
        }))).data;
    },

    async CallOperationUrl(path, query) {
        const [integration, operation, ...args] = path.split('/');
        const slug = args.join('/');
        return (await checkErrors(await fetchApi.api.post(`integrations/${integration}/call/${operation}/${slug}`, {
            query
        }))).data;
    },

}



export default IntegrationsApi;
