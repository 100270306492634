import { TextField } from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { useMemo } from "react";

dayjs.extend(utc);

export default function MuiDateTimePicker(props) {

  const {
    value,
    onChange,
    id, required, disabled, readonly, autofocus,
    uiSchema: {
      "ui:minutesStep": minutesStep,
      "ui:timeSteps": timeSteps,
      "ui:minDate": minDateOptions
    },
  } = props;

  const minDate = minDateOptions === "current" ? dayjs() : null;

  const displayValue = useMemo(() => {

    if (!value) return null;

    const date = dayjs(value);

    const formattedDate = date.format('MM/DD/YYYY hh:mm A');

    return dayjs(formattedDate);

  }, [value]);

  const isReadonly = disabled || readonly;

  return (
    <div className="form-group">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div data-cy={id}>
          {isReadonly ?
            <TextField
              disabled
              value={value ? dayjs(value).format('MM/DD/YYYY hh:mm A') : null}
            /> :
            <DateTimePicker
              value={displayValue}
              slotProps={{ textField: { id } }}
              onChange={newValue => {
                if (!newValue || !newValue.isValid()) {
                  return;
                }
                const formattedDate = newValue.format('MM/DD/YYYY hh:mm A');
                return onChange(formattedDate)
              }}
              minutesStep={minutesStep}
              timeSteps={timeSteps}
           //   timezone="UTC"
              minDate={minDate}
              sx={{ width: 319 }}
            />}
        </div>
      </LocalizationProvider>
    </div>
  );
}
