import React, { useMemo } from "react";
import { FormGroup, Input } from "reactstrap";


function CheckboxToggleButtonField(props) {
    const {
        formData,
        disabled,
        idSchema: { $id },
        schema: {
            title,
            color = 'secondary',
            checkedColor = 'success'
        },
        uiSchema: {
            'ui:readonly': uiReadonly,
            'ui:checkboxHidden': checkboxHidden,
        },
        required,
        readonly: propReadOnly,
        onChange: propOnChange
    } = props;

    const readonly = uiReadonly || propReadOnly;

    const currentColor = ((formData && checkedColor) ? checkedColor : color) || 'secondary';

    function onChange({ target: { value, checked } }) {
        console.log("onChange", value, checked);
        propOnChange(checked);
    }

    return (
        <FormGroup
            disabled={readonly || disabled}
            check
            className="checkbox-toggle-button-field"
        >
            <label
                className={`control-label checkbox btn btn-${currentColor} ${checkboxHidden ? 'checkbox-hidden' : ''} ${formData ? 'checked' : ''}`}
                htmlFor={$id}
                data-cy={$id}
            >
                <Input
                    id={$id}
                    type="checkbox"
                    disabled={readonly || disabled}
                    required={required}
                    checked={!!formData}
                    onChange={onChange}
                />
                {title}
            </label>
        </FormGroup>
    );
}

export default CheckboxToggleButtonField;