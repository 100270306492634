import { t } from "@lingui/macro";
import Dashboard from "../../../components/Dashboard/index";


const ListDashboard = Dashboard.HOC({
    slug: '?dashboard=',
    schema: {
        "title": t`Dashboards`,
        "type": "dashboard",
        "elements": [{
            "title": t`Dashboards`,
            "type": "worktray",
            ":className": "col-lg-12",
            "resource": "dashboards",
            "tableActions": [{
                "label": t`Create Dashboard`,
                "route": "${dashboard.slug}new",
            }],
            "filters": [
                { "title": t`Name`, "key": "name", "type": "string" },
                { "title": t`Slug`, "key": "slug", "type": "string" },
            ],
            "columns": [
                { "title": t`Order`, "sortkey": "order", "map:field": "order", "style": { "textAlign": "center" } },
                { "title": t`Name`, "sortkey": "name", "map:field": "name", "map:image": "image", "iconSize": "1.4em" },
                { "title": t`Slug`, "sortkey": "slug", "map:field": "slug" },
                { "title": t`Version`, "sortkey": "version", "map:field": "version" },
                { "title": t`Last Updated`, "sortkey": "lastUpdated", "map:field": "lastUpdated", "format": "date" },
                {
                    "title": t`Actions`, "actions": [{
                        "label": t`Edit`,
                        "route": "${dashboard.slug}$id",
                    },
                    {
                        "label": t`Delete`,
                        "type": "ConfirmButton",
                        "confirm": {
                            "text": "Está seguro que desea eliminar el Dashboard?",
                            "api": "manage/dashboards/$id",
                            "method": "delete"
                        }
                    }]
                },
            ]
        }]
    }
});


export default ListDashboard;