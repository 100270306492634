import React, { useState, useMemo } from 'react';
import { Button, Input } from 'reactstrap';
import { QrReader } from 'react-qr-reader';
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import getPathFromId from '../../util/getPathFromId';
import { useJnx } from "../../util/jnx";

function QrRead(props) {
  const {
    formData: value,
    formContext: {
      setFormDataValues,
      sideChannel
    },
    onChange,
    idSchema: { $id } = {},
    schema: { title },
    uiSchema: {
      'ui:onSelect': onSelectExpr,
    },
    required
  } = props;

  const [show, setShow] = useState(false);

  const rootFormData = useSideChannelSubscription(sideChannel, 0);
  const functionBinds = useMemo(() => ({ set: setFormDataValues }), [setFormDataValues]);
  const onSelectJnx = useJnx(onSelectExpr, { functionBinds });

  const path = useMemo(() => getPathFromId($id), [$id]);

  const clean = () => {
    setShow(false);
    onChange(undefined);
  }

  useDebouncedEffect(() => {

    if (value) {

      if (onSelectJnx) {
        setTimeout(() => {
          onSelectJnx.eval(rootFormData, '', { fieldPath: path, object: value });
        }, 500);
        clean();
      }

    }
  }, [value], 450);

  function convertTextToObject(text) {
    const parts = text.split(', ');
    const object = {};

    parts.forEach(part => {
      let key = part.split(': ')?.[0];
      const value = part.split(': ')?.[1];
      if (key === "Vin") key = "vinNumber";
      if (key === "License") key = "licensePlate";
      if (key === "Property") key = "propertyNumber";
      object[key.trim()] = value.trim();
    });

    return object;
  }

  function detectDataType(data) {
    try {
      const parsedData = JSON.parse(data);
      if (parsedData && typeof parsedData === 'object') {
        return 'JSON';
      }
    } catch (error) {
      return 'Text';
    }
    return 'Text';
  }

  return (
    <>
      <label className="control-label">
        {title}{required ? <span className="required">*</span> : null}
      </label>
      <div className=''>
        {show ?
          <>
            <Button onClick={() => clean()} title="Cancel">
              <i className="fa fa-times text-danger" />
            </Button>
            <QrReader
              containerStyle={{ height: '168px' }}
              videoStyle={{ height: '168px', width: '50%' }}
              constraints={{ facingMode: 'environment' }}
              onResult={(result, error) => {
                let qr = result?.text;
                if (qr) {
                  if (detectDataType(qr) === "Text") {
                    qr = convertTextToObject(qr);
                  } else {
                    qr = JSON.parse(qr);
                  }
                  onChange(qr);
                  setShow(false);
                }
              }} />
          </>
          : <Button onClick={() => setShow(true)}><i className="fa fa-qrcode" /> Scan QR</Button>}
      </div>
    </>
  );
}

export default QrRead;