// import { Trans } from '@lingui/react';
import { t } from "@lingui/macro";
import React, { useContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import AuthApi from '../api/AuthApi';
import { AuthContext } from '../context/AuthContext';
import FormComponent from '../components/FormComponent';
import InternalLayout from '../components/layouts/InternalLayout';
import { FORM_UPDATE_SUBMIT_BUTTON } from '../constants';
import Notification from '../components/Notification';
import { makeUserSchema } from './users/EditUser';
import LicenseGeneration from "../blc/LicenseGeneration";
import UWEEntityApi from '../api/UWEEntityApi';
import Loader from '../components/Loader';



const MyProfile = FormComponent({
    displayName: 'MyProfile',
    withoutNavigation: true,
    schema: makeUserSchema({
        title: "Mi Perfil",
        onlyEditUserData: true,
        hidePassword: true,
        hideVehicle: true,
    }),
    parseProps: () => ({
        auth: useContext(AuthContext),
    }),
    loadData: () => AuthApi.getSelf(),
    async onSubmit({ formData }, { props: { auth } }) {
        const user = await AuthApi.updateSelf(formData);
        if (user) {
            auth.setCompletedProfile(user.data.hasCompletedProfile);
        }
    },
    renderFormSubmitted: () => (
        <Redirect path="/" />
    ),
    renderSubmitBarComponentsFn: () => (
        <AsyncLicenseBar/>
    ),
    submitButtons: {
        ...FORM_UPDATE_SUBMIT_BUTTON,
    },
});

function AsyncLicenseBar () {
    const [driverId, setDriverId] = useState(null);
    useEffect(() => {
        const fetchDriverId = async () => {
            const id = await GetDriverId();
            setDriverId(id);
        };
        fetchDriverId();
    }, []);

    if (driverId === null) {
        return <Loader />;
    }
    if(driverId === -1){
        return "  No License Found"
    }

    return <LicenseGeneration currentEntityId={driverId} />;
};

async function GetDriverId(){
    const user = await AuthApi.getSelf();
    const resource = `uwe-entities/DriverLicense/user-entity`;
    const data = await UWEEntityApi.getUserEntity({ resource, sortBy: `metadata.number`});
    if(data[0]){
        return data[0].id;
    }
    return -1;
}

export default function Profile() {

    const auth = useContext(AuthContext);

    return <InternalLayout>
        {!auth?.user?.user?.hasCompletedProfile ? <Notification color="warning">Su perfil se encuentra incompleto</Notification> : null}
        <MyProfile />
    </InternalLayout>;
};
