import ReactHtmlParser from "react-html-parser";
import DOMPurify from "dompurify";
import InternalLayout from '../../components/layouts/InternalLayout';
import useTitle from "../../util/useTitle";
import useResourceLoader from "../../util/useResourceLoader";

export default function TicketPage(props) {
    useTitle("Ayuda");
    const [htmlPage, loading] = useResourceLoader(
      () => fetch("/zohoForm.html").then(r => r.text()),
      []
    );
    const sanitizedHtmlPage = DOMPurify.sanitize(htmlPage);
    return (
        <InternalLayout>
            <div>{ReactHtmlParser(sanitizedHtmlPage)}</div>
        </InternalLayout>
    );
}