import React from 'react';
import Home from './views/Home';
import Login from './views/Login';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
// import CreateUser from "./views/CreateUser";
import WorkflowTray from './views/workflow/tray';
import WorkflowNewEntity from './views/workflow/new';
import WorkflowEntityDetails from './views/workflow/details';
import WorkflowEntityLog from './views/workflow/log';
import WorkflowViewEntity from './views/workflow/view';
import CreateNewUser from "./views/users/CreateNewUser";
import UsersTable from './views/users/UsersTable';
import EditUser from './views/users/EditUser';
import Profile from './views/Profile';
import EditOrganizationalUnit from './views/SystemSettings/OrganizationalUnit/EditOrganizationalUnit';

import SystemSettings from './views/SystemSettings';

// import forms from "./views/forms/forms";
import forms from './views/forms/forms';
import { Help } from "./views/Help";
import EditIntegration from './views/SystemSettings/Integrations/EditIntegration';
import CreateIntegration from './views/SystemSettings/Integrations/CreateIntegration';
import IntegrationUser from './views/users/IntegrationUser';
import IntegrationUserTest from './views/users/test/IntegrationUserTest';
import ProcessLogin from './views/integration/ProcessLogin';
import TicketPage from './views/TicketPage'
import EditItem from './views/SystemSettings/Invoce/EditItem';
import CreateItem from './views/SystemSettings/Invoce/CreateItem';
import CreateOrganizationalUnit from './views/SystemSettings/OrganizationalUnit/CreateOrganizationalUnit';
import ListInvoiceItem from './views/SystemSettings/Invoce/ListInvoiceItem';
import AnonymousAccessView from './views/anonymous/AnonymousAccessView';
import ThankyouMessage from './views/ThankyouMessage';
import ConfirmationEmailForm from './views/ConfirmationEmailForm';
import Faq from './views/Faq';
import IntegrationsTable from './views/SystemSettings/Integrations/IntegrationsTable';


const routes = {

    '/process-login': { component: ProcessLogin, excludeFromProfileCheck: true },
    '/login': { component: Login, default: 1, excludeFromProfileCheck: true },
    '/forgot-password': { component: ForgotPassword, excludeFromProfileCheck: true },
    '/reset-password/:token': { component: ResetPassword, excludeFromProfileCheck: true },
    '/register': { component: ResetPassword, excludeFromProfileCheck: true },

    '/thankyou': { exact: true, component: ThankyouMessage, excludeFromProfileCheck: true },
    '/confirmationEmailForm/:token': { exact: true, component: ConfirmationEmailForm, excludeFromProfileCheck: true },


    '/': { exact: true, component: Home, default: 10 },

    '/workflow/:slug/tray': { exact: true, component: WorkflowTray, requires: 'login' },
    '/workflow/:entityType/new': { exact: true, component: WorkflowNewEntity, requires: 'login' },
    '/workflow/:entityType/:id': { exact: true, component: WorkflowEntityDetails, requires: 'login' },
    '/workflow/:entityType/:id/view': { exact: true, component: WorkflowViewEntity, requires: 'login' },
    '/workflow/:entityType/:id/log': { exact: true, component: WorkflowEntityLog, requires: 'permissions.ManageWorkflow' },

    '/anonymous/:tenant/': { component: AnonymousAccessView, excludeFromProfileCheck: true },

    ...(Object.entries(forms).reduce((_, [path, component]) => {
        _[`/${path}`] = {
            component,
        };
        return _;
    }, {})),

    '/users/create': { component: CreateNewUser, exact: true, requires: 'login' },
    '/users/:id': { component: EditUser, exact: true, requires: 'login' },
    '/users': { component: UsersTable, exact: true, requires: 'login' },

    '/integration/user/:jediid/settings': { component: IntegrationUser, exact: true, excludeFromProfileCheck: true },
    '/test/integration/users/iframe': { component: IntegrationUserTest, exact: true },

    '/profile': { component: Profile, exact: true, requires: 'login', excludeFromProfileCheck: true },
    
    '/faq': { component: Faq, exact: true, requires: 'login', excludeFromProfileCheck: true },

    '/system-settings': { component: SystemSettings, exact: true, requires: 'login' },
    '/system-settings/:setting': { component: SystemSettings, requires: 'login' },
    '/support': { component: Help, requires: 'login' },

    // organizationalUnits
    '/organizationalunits/create': { component: CreateOrganizationalUnit, requires: 'login' },
    '/organizationalunits/:id': { component: EditOrganizationalUnit, requires: 'login' },
    // integrations
    '/integrations/create': { component: CreateIntegration, exact: true, requires: 'login' },
    '/integrations/:id': { component: EditIntegration, requires: 'login' },
    '/integrations': { component: IntegrationsTable, exact: true, requires: 'login' },
    
    '/create-ticket': {component: TicketPage, requires: 'login'},

    '/invoice/create': { component: CreateItem, exact: true, requires: 'login' },
    '/invoice/:id': { component: EditItem, exact: true, requires: 'login' },
    '/invoice': { component: ListInvoiceItem, exact: true, requires: 'login' },
}


export default routes;