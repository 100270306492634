import { useMemo, useEffect } from "react";
import useResourceLoader from '../../../util/useResourceLoader';
import fetchApi from "../../../util/fetchApi";
import checkErrors from "../../../api/checkErrors";
import { useSideChannelSubscription } from "../../../util/useSideChannel";
import { getObject } from "../../../util/mapObject";
import Jnx, { useJnx, useMultipleJnx } from "../../../util/jnx";

function useOptionsLookup({
    lookup,
    rootFormData,
    formContext
}) {
    const {
        api: propApi,
        entity,
        jnx: apiResponseJnxExpr,
        params,
        options: fetchOptions,
        requiredParams = false,
    } = lookup || {};

    const apiResponseJnx = useJnx(apiResponseJnxExpr);

    const paramsJnx = useMultipleJnx(params);

    const paramsString = useMemo(() => computeParamString(rootFormData, params, paramsJnx, formContext), [rootFormData, params, paramsJnx, formContext]);

    const [options, loadingOptions, errorLoadingOptions] = useResourceLoader(async () => loadOptionsData(propApi, apiResponseJnx, paramsString, fetchOptions, entity, requiredParams), [propApi, apiResponseJnx, paramsString, fetchOptions, entity]);

    return {
        options,
        loadingOptions,
        errorLoadingOptions
    }
}

export function lookupOptions(lookup, rootFormData) {
    const {
        api: propApi,
        entity,
        jnx: apiResponseJnxExpr,
        params,
        options: fetchOptions,
        requiredParams = false,
    } = lookup || {};

    const apiResponseJnx = apiResponseJnxExpr ? new Jnx(apiResponseJnxExpr) : null;

    const paramsString = computeParamString(rootFormData, params);

    return loadOptionsData(propApi, apiResponseJnx, paramsString, fetchOptions, entity, requiredParams);
}

function computeApiUrl(api, paramsString) {
    let module = null;
    let listFn = null;

    let m = /^(api|entity):(.+)$/.exec(api);
    if (m) {
        if (m[1] === 'entity') {
            listFn = (x) => x.items;
        }
        module = "api";
        api = m[1] === 'entity' ? `uwe-entities/${m[2]}/list` : m[2];
    } else {
        m = /^https?:\/\/(.+)$/.exec(api);
        if (m) {
            module = 'external';
        } else {
            module = 'lookup';
        }
    }

    const delimiter = api.indexOf('?') > -1 ? '&' : '?';
    const url = `${api}${paramsString ? `${delimiter}${paramsString}` : ''}`;

    return [module, url, listFn];
}



function computeParamString(rootFormData, params, paramsJnx, formContext) {
    if (!params || typeof params !== 'object') {
        return '';
    }

    if (paramsJnx && rootFormData) {
        Object.entries(params).forEach(([k, v]) => {
            if (k.toString().startsWith("expr:")) {
                const key = k.replace(/^expr:/, "");
                if (paramsJnx[key]) {
                    delete params[k];
                    const jnx = paramsJnx[key].eval(rootFormData || {}, '', {
                        root: rootFormData,
                        formContext: formContext || {}
                    });
                    params[key] = jnx;
                }
            }
        });
    }

    return rootFormData || (params && params)
        ? Object.entries(params)
            .map(([k, v]) => {
            if (v && v.field)
                v = rootFormData
                ? getObject(rootFormData, v.field)
                : v.default || "";
            return v ? `${k}=${encodeURIComponent(v)}` : null;
            })
            .filter((s) => !!s)
            .join("&")
        : "";
}

async function loadOptionsData(propApi, apiResponseJnx, paramsString, fetchOptions, entity, requiredParams) {
    if(requiredParams && paramsString?.length === 0) return;

    const api = entity ? `entity:${entity}` : propApi;
    if (!api) return null;
    const [module, url, listFn] = computeApiUrl(api, paramsString);
    let data = await checkErrors(await fetchApi[module].get(url, fetchOptions));
    if (listFn) {
        data = listFn(data);
    }
    if (apiResponseJnx) {
        data = apiResponseJnx.eval(data, '', { data });
    }
    return data ? (Array.isArray(data) ? data : [data]) : null;
}



export default useOptionsLookup;