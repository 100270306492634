import React from "react";
import useSearchParams from "../../../util/useSearchParams";
import AddEditFaq from "./AddEditFaq";
import ListFaqs from "./ListFaqs";

function Faqs() {
    const [
        [faq, setFaq]
    ] = useSearchParams({
        faq: undefined
    });

    return faq ? (
        <AddEditFaq id={faq} setFaq={setFaq} />
    ) : (
        <ListFaqs setFaq={setFaq} />
    );
}

export default Faqs;