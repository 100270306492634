import React, { useState, useCallback, useContext, useMemo } from 'react';
import { NavbarToggler } from "reactstrap";
import { useHistory, useRouteMatch } from 'react-router';

import TabPanel from '../TabPanel';
import AppContext from '../../context/AppContext';
import UWEContext from '../../context/UWEContext';
import { AuthContext } from '../../context/AuthContext';


function Sidebar(props) {
    const {
        tabs,
        sidebarTab,
        setSelected,
    } = useSidebarHooks(props);
    const [collapsed, setCollapsed] = useState(() => {
        const vpwidth = window.innerWidth;
        if(window.sessionStorage.getItem("sidebar") == null){
            window.sessionStorage.setItem("sidebar", vpwidth < 1000 ? "closed" : "open");
            return vpwidth < 1000;
        }
        return window.sessionStorage.getItem("sidebar") === "closed";
    });
    function toggleNavbar() {
        window.sessionStorage.setItem("sidebar", !collapsed ? "closed" : "open");
        setCollapsed(!collapsed);
    }
    // console.log(sidebarTab)

    return (
        <>
            <div className="sidebar-hamburger">
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            </div>
            <div className={`sidebar ${collapsed ? "is-collapsed" : "is-not-collapsed"}`}>
                <TabPanel
                    noText
                    selected={sidebarTab}
                    setSelected={setSelected}
                    tabs={tabs}
                    showContent={false}
                    isCollapsed={collapsed}
                />
            </div>
        </>);
}

function useSidebarHooks({
    propTabs,
    propActiveTab,
    propSetActiveTab
}) {
    const usePropTabs = propSetActiveTab && propSetActiveTab;
    const { params: { slug: sidebarTab } } = useRouteMatch();

    const { sidebarExpanded } = useContext(AppContext);
    const { dashboards } = useContext(UWEContext);
    const history = useHistory();

    const dashboardTabs = useMemo(() => dashboards?.reduce((_, db) => {
        _[db.slug] = { ...db.image, path: `/workflow/${db.slug}/tray`, name: db.name };
        return _;
    }, {}), [dashboards]);


    const auth = useContext(AuthContext);

    const filterDashboard = dashboardTabs;
    if(auth?.user && "Agencia Gerente Transporte" in auth?.user.rolesByName){
        delete filterDashboard.home;
    }

    const setSelected = useCallback((newSelected) => {
        const obj = filterDashboard[newSelected];
        console.log("setSelected", newSelected, sidebarTab, newSelected === sidebarTab);

        if (obj && obj.path) {
            history.push(obj.path);
        }
    }, [sidebarTab, sidebarExpanded, filterDashboard]);

    return usePropTabs ? {
        tabs: filterDashboard,
        sidebarTab: propActiveTab,
        setSelected,
    } : {
        tabs: filterDashboard,
        sidebarTab,
        setSelected
    };
}


export default Sidebar;