import React, { useContext, useState } from 'react';
import { Card, Alert, CardBody, CardHeader, ListGroup, ListGroupItem, Button, Collapse } from 'reactstrap';
import { useHistory } from 'react-router';

import useResourceLoader from "../util/useResourceLoader";
import useTitle from "../util/useTitle";
import Loader from '../components/Loader';
import CollapsibleCard from '../components/CollapsibleCard';
import ManageFaqApi from '../api/ManageFaqApi';
import InternalLayout from '../components/layouts/InternalLayout';
import Notification from '../components/Notification';
import { AuthContext } from '../context/AuthContext';
import WorkTray from "../components/Dashboard/WorkTray";


const DOCUMENTS = [

    // { title: "Autorización de viajes", url: "https://workdrive.zoho.com/file/aqzr00207ea61d8fc4ef187d0ef859d8865de"},
    // { title: "Bitácoras", url: "https://workdrive.zoho.com/file/aqzr0a6a0a55c9de74abba29f705fa63073f6"},
    // { title: "Onboarding - Gerentes", url: "https://workdrive.zoho.com/file/aqzr05e4cc07139a44da5a7bfb968b372da1b"},
    // { title: "Adiestramiento para Gerentes y Auxiliares", url: "https://dimo.s3.amazonaws.com/faqDocs/Fast+Fleet+Training+para+Gerentes+de+Transporte.pdf"},
    { title: "Fast Fleet - Adiestramiento para Gerentes y Auxiliares", url: "https://dimo.s3.amazonaws.com/faqDocs/Fast+Fleet+Training+para+Gerentes+de+Transporte+V3.pdf"},
    { title: "Fast Fleet - Olvidé mi contraseña", url: "https://dimo.s3.amazonaws.com/faqDocs/Fast+Fleet+-+Ovide+mi+contrasen%CC%83a.pdf"},
    { title: "Fast Fleet - Creación de Tickets", url: "https://dimo.s3.amazonaws.com/faqDocs/Creacio%CC%81n+de+Tickets+-+Fast+Fleet.pdf"},
    { title: "Fast Fleet - Onboarding", url: "https://dimo.s3.amazonaws.com/faqDocs/Onboarding.pdf"},
];


const VIDEOS = [
    { title: "Módulo de Bitácoras", url: "https://www.youtube.com/embed/LbQ4ETjmGho"},
    { title: "Autorización de viajes", url: "https://www.youtube.com/embed/YGjelogfq2I"},
    { title: "Registro de vehículo", url: "https://www.youtube.com/embed/mqybaCPMO2w"},
    { title: "Renovación de marbete", url: "https://www.youtube.com/embed/fYjomZ3YF-E"},
    { title: "Tarjeta de combustible", url: "https://www.youtube.com/embed/e5BcdKMvtng"},
    { title: "¿Cómo anejar documentos?", url: "https://www.youtube.com/embed/u5DNLh7fXss"},
];

const dashboardWorktray = {
    "element": {
        "type": "worktray",
        "colSpan": 4,
        "title": " ",
        "entityType": "HelpDeskTicket",
        "resource": "uwe-entities/objectSchema/HelpDeskTicket",
        "lookupParams": {},
        "tableActions": [
            {
                "label": "Crear Ticket",
                "backgroundColor": "#00AFB9",
                "route": "/workflow/tickets/new"
            }
        ],
        "defaultFilter": "-date",
        "filters": [
            {
                "title": "Nombre",
                "key": "metadata-like-name",
                "type": "string"
            },
            {
                "title": "Fecha Desde",
                "key": "after",
                "type": "string",
                "format": "date"
            },
            {
                "title": "Fecha Hasta",
                "key": "before",
                "type": "string",
                "format": "date"
            },
            {
                "title": "Etapa",
                "key": "stage",
                "type": "string"
            },
            {
                "title": "Problema",
                "key": "metadata-like-subject",
                "type": "string"
            },
            {
                "title": "Estatus",
                "key": "metadata-like-ticketStatus",
                "type": "string"
            },
            {
                "title": "Mostrar Solicitudes Cerradas",
                "key": "isClosed",
                "type": "boolean"
            },
            {
                "title": "Mostrar Solicitudes Archivadas",
                "key": "isArchived",
                "type": "boolean"
            }
        ],
        "columns": [
            {
                "title": "Solicitud",
                "sortkey": "metadata.application",
                "map:field": "metadata.application"
            },
            {
                "title": "Fecha",
                "sortkey": "createdAt",
                "map:field": {
                    "expr": "$onlyDate(createdAt)"
                },
                "format": ""
            },
            {
                "title": "Nombre",
                "sortkey": "metadata.name",
                "map:field": "metadata.name"
            },
            {
                "title": "Etapa",
                "sortkey": "status.0.name",
                "map:field": "status.0.name"
            },
            {
                "title": "Problema",
                "sortkey": "metadata.subject",
                "map:field": "metadata.subject"
            },
            {
                "title": "Estatus",
                "sortkey": "metadata.ticketStatus",
                "map:field": "metadata.ticketStatus"
            },
            {
                "title": "Días Abierto",
                "sortkey": "metadata.application",
                "map:field": {
                    "expr": "$getBusinessDatesCount(lastUpdated, $now())"
                }
            },
            {
                "title": "Agencia",
                "sortkey": "organizationalUnit.name",
                "map:field": "organizationalUnit.name"
            },
            {
                "title": "Acciones",
                "actions": [
                    {
                        "label": "Trabajar",
                        "backgroundColor": "#10112B",
                        "route": "/workflow/tickets/$id",
                        "require": [
                            {
                                "flag": "canWork"
                            }
                        ]
                    },
                    {
                        "label": "Ver",
                        "backgroundColor": " #15416F",
                        "route": "/workflow/tickets/$id/view",
                        "restrict": [
                            {
                                "flag": "canWork"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "history": {
        "length": 3,
        "action": "PUSH",
        "location": {
            "pathname": "/workflow/tickets/tray",
            "search": "",
            "hash": "",
            "key": "l9s23h"
        }
    },
    "scope": {
        "dashboard": {
            "wrapperType": "internal",
            "id": 11,
            "order": 3,
            "slug": "tickets",
            "name": " ",
            "image": {
                "icons": {
                    "base": "dimo-icon dimo-icon-v_uso",
                    "paths": [
                        "p1"
                    ]
                }
            },
            "definition": {
                "type": "dashboard",
                "elements": [
                    {
                        "type": "worktray",
                        "colSpan": 4,
                        "title": " ",
                        "entityType": "HelpDeskTicket",
                        "resource": "uwe-entities/objectSchema/HelpDeskTicket",
                        "lookupParams": {},
                        "tableActions": [
                            {
                                "label": "Crear Ticket",
                                "backgroundColor": "#00AFB9",
                                "route": "/workflow/tickets/new"
                            }
                        ],
                        "defaultFilter": "-date",
                        "filters": [
            {
                "title": "Nombre",
                "key": "metadata-like-name",
                "type": "string"
            },
            {
                "title": "Fecha Desde",
                "key": "after",
                "type": "string",
                "format": "date"
            },
            {
                "title": "Fecha Hasta",
                "key": "before",
                "type": "string",
                "format": "date"
            },
            {
                "title": "Etapa",
                "key": "stage",
                "type": "string"
            },
            {
                "title": "Problema",
                "key": "metadata-like-subject",
                "type": "string"
            },
            {
                "title": "Estatus",
                "key": "metadata-like-ticketStatus",
                "type": "string"
            },
            {
                "title": "Mostrar Solicitudes Cerradas",
                "key": "isClosed",
                "type": "boolean"
            },
            {
                "title": "Mostrar Solicitudes Archivadas",
                "key": "isArchived",
                "type": "boolean"
            }
        ],
        "columns": [
            {
                "title": "Solicitud",
                "sortkey": "metadata.application",
                "map:field": "metadata.application"
            },
            {
                "title": "Fecha",
                "sortkey": "createdAt",
                "map:field": {
                    "expr": "$onlyDate(createdAt)"
                },
                "format": ""
            },
            {
                "title": "Etapa",
                "sortkey": "status.0.name",
                "map:field": "status.0.name"
            },
            {
                "title": "Problema",
                "sortkey": "metadata.subject",
                "map:field": "metadata.subject"
            },
            {
                "title": "Estatus",
                "sortkey": "metadata.ticketStatus",
                "map:field": "metadata.ticketStatus"
            },
            {
                "title": "Días Abierto",
                "sortkey": "metadata.application",
                "map:field": {
                    "expr": "$getBusinessDatesCount(lastUpdated, $now())"
                }
            },
            {
                "title": "Agencia",
                "sortkey": "organizationalUnit.name",
                "map:field": "organizationalUnit.name"
            },
            {
                "title": "Acciones",
                "actions": [
                    {
                        "label": "Trabajar",
                        "backgroundColor": "#10112B",
                        "route": "/workflow/tickets/$id",
                        "require": [
                            {
                                "flag": "canWork"
                            }
                        ]
                    },
                    {
                        "label": "Ver",
                        "backgroundColor": " #15416F",
                        "route": "/workflow/tickets/$id/view",
                        "restrict": [
                            {
                                "flag": "canWork"
                            }
                        ]
                    }
                ]
            }
        ]
                    }
                ]
            },
            "version": 20,
            "lastUpdated": "2023-07-10T15:49:27.0562558",
            "schema": {
                "type": "dashboard",
                "elements": [
                    {
                        "type": "worktray",
                        "colSpan": 4,
                        "title": " ",
                        "entityType": "HelpDeskTicket",
                        "resource": "uwe-entities/objectSchema/HelpDeskTicket",
                        "lookupParams": {},
                        "tableActions": [
                            {
                                "label": "Crear Ticket",
                                "backgroundColor": "#00AFB9",
                                "route": "/workflow/tickets/new"
                            }
                        ],
                        "defaultFilter": "-date",
                        "filters": [
            {
                "title": "Nombre",
                "key": "metadata-like-name",
                "type": "string"
            },
            {
                "title": "Fecha Desde",
                "key": "after",
                "type": "string",
                "format": "date"
            },
            {
                "title": "Fecha Hasta",
                "key": "before",
                "type": "string",
                "format": "date"
            },
            {
                "title": "Etapa",
                "key": "stage",
                "type": "string"
            },
            {
                "title": "Problema",
                "key": "metadata-like-subject",
                "type": "string"
            },
            {
                "title": "Estatus",
                "key": "metadata-like-ticketStatus",
                "type": "string"
            },
            {
                "title": "Mostrar Solicitudes Cerradas",
                "key": "isClosed",
                "type": "boolean"
            }
        ],
        "columns": [
            {
                "title": "Núm. Ticket",
                "sortkey": "metadata.application",
                "map:field": "metadata.application"
            },
            {
                "title": "Fecha",
                "sortkey": "createdAt",
                "map:field": {
                    "expr": "$onlyDate(createdAt)"
                },
                "format": ""
            },
            {
                "title": "Etapa",
                "sortkey": "status.0.name",
                "map:field": "status.0.name"
            },
            {
                "title": "Problema",
                "sortkey": "metadata.subject",
                "map:field": "metadata.subject"
            },
            {
                "title": "Estatus",
                "sortkey": "metadata.ticketStatus",
                "map:field": "metadata.ticketStatus"
            },
            {
                "title": "Días Abierto",
                "sortkey": "metadata.application",
                "map:field": {
                    "expr": "$getBusinessDatesCount(lastUpdated, $now())"
                }
            },
            {
                "title": "Agencia",
                "sortkey": "organizationalUnit.name",
                "map:field": "organizationalUnit.name"
            },
            {
                "title": "Acciones",
                "actions": [
                    {
                        "label": "Trabajar",
                        "backgroundColor": "#10112B",
                        "route": "/workflow/tickets/$id",
                        "require": [
                            {
                                "flag": "canWork"
                            }
                        ]
                    },
                    {
                        "label": "Ver",
                        "backgroundColor": " #15416F",
                        "route": "/workflow/tickets/$id/view",
                        "restrict": [
                            {
                                "flag": "canWork"
                            }
                        ]
                    }
                ]
            }
        ]
                    }
                ]
            }
        },
        "dashboardData": {}
    }
}

export function Faq() {
  const auth = useContext(AuthContext);
  const [isOpenDoc, setIsOpenDoc] = useState(false);
  const [isOpenVid, setIsOpenVid] = useState(false);
  const [isOpenFAQ, setIsOpenFAQ] = useState(false);
  const [isOpenTicket, setIsOpenTicket] = useState(false);

  const toggleDoc = () => setIsOpenDoc(!isOpenDoc);
  const toggleVid = () => setIsOpenVid(!isOpenVid);
  const toggleFAQ = () => setIsOpenFAQ(!isOpenFAQ);
  const toggleTicket = () => setIsOpenTicket(!isOpenTicket);


  const history = useHistory();
  useTitle("Ayuda");
  console.log("+++++++++++++++++++++ auth:",auth);
  const [listFaqs, loadingFaqs] = useResourceLoader(
    () => ManageFaqApi.getFaqs(),
    [], null);

  return (
    <InternalLayout>
      
      <div>
        <h1>Ayuda</h1><br />
        <div>
          {DOCUMENTS.length || VIDEOS.length ? (<>
              {DOCUMENTS.length ? (<>
                <CollapsibleCard title="Documentos">
                  <ListGroup>
                    {DOCUMENTS.map(({title, url}, idx) => (<ListGroupItem key={idx}>
                      <a href={url} target="_blank" rel="noreferrer">{title}</a>
                    </ListGroupItem>))}
                  </ListGroup>
                </CollapsibleCard>
                </>) : null
              }
              {DOCUMENTS.length ? (<>
                  <CollapsibleCard title="Videos de Entrenamiento">
                    <div className="d-flex flex-wrap flex-row">
                      {VIDEOS.map(({title, url}, idx) => (<Card className="mb-4">
                        <CardBody key={idx}>
                          <h4 className="text-default">{title}</h4>
                          <iframe width="560" height="315" src={url} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                        </CardBody>
                      </Card>))}
                    </div>
                  </CollapsibleCard>
                </>) : null
              }
            </>) : null}
        </div> 
      </div>
      {loadingFaqs ? <Loader /> : (
        <>
          <CollapsibleCard title="Preguntas Frecuentes">
            <div className="d-flex flex-wrap flex-row">
            {listFaqs?.length ?
              listFaqs?.map((faq, key) => (
                <FaqCard key={key} faq={faq} />
              )) :
              <Alert color="info">No faqs have been created yet</Alert>
            }
            </div>
          </CollapsibleCard>
        </>
      )}

      {DOCUMENTS.length ? (<>
        <CollapsibleCard title="Tickets">
          <WorkTray element={dashboardWorktray.element} scope={dashboardWorktray.scope}/>
        </CollapsibleCard>
        </>) : null
      }
      {auth?.user?.user?.isSuperuser && false ?
        (<div>
          <h3>Crear un Ticket</h3>
          <h4>¿Necesitas ayuda directo al equipo? Crea un ticket.</h4>
          <Button onClick={() => {history.push(`/create-ticket`)}} color="info">
            Crear Ticket
          </Button>
        </div>) : null
      }
    </InternalLayout>
  )
};

export default Faq;

function FaqCard({
  faq
}) {
  const {
    content,
    createdAt,
  } = faq || {};

  return (
    <Card style={{ padding: 1, marginBlockEnd:20, border: 0, boxShadow: 0, borderBottom: '1.5px solid #ccc' }}>
      <CardHeader>
        <span>{formatDate(createdAt)}</span>
      </CardHeader>
      <CardBody>
        {HTMLCodeDisplay(content)}
      </CardBody>
    </Card>
  );
}

const HTMLCodeDisplay = (htmlCode) => {
  return <div dangerouslySetInnerHTML={{ __html: `<div style="font-family: 'Inter'">${htmlCode}</div>` }} />;
};

const formatDate = (dateStr) => {
  if (!dateStr)
    return "";
  const date = new Date(dateStr);
  const monthAbbr = date.toLocaleString('default', { month: 'short' }).toUpperCase();
  const formattedDate = `${monthAbbr}-${date.getDate()}-${date.getFullYear()}`;
  return formattedDate;
}