import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';


const NotificationsApi = {
    async list({ offset, size, sortBy } = {}) {
        return (await checkErrors(await fetchApi.api.get('notifications', { query: { offset, size, sortBy }, useCache: false }))).data;
    },

    async markAsRead({ id }) {
        return (await checkErrors(await fetchApi.api.post(`notifications/mark-read/${id}`))).data;
    },
}


export default NotificationsApi;
