import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';


export const FileApi = {
    async uploadFile({ file, entityId }) {
        return checkErrors(
            await fetchApi.api.post(`uploaded-file`, {formdata: {file, entityId}})
        )
    },

    async getFile({ fileId }) {
        return checkErrors(
            await fetchApi.api.get(`uploaded-file/${fileId}`)
        )
    },

    async deleteFile({ fileId }) {
        return checkErrors(
            await fetchApi.api.delete(`uploaded-file/${fileId}`)
        )
    },
}


export default FileApi;
