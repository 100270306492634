import React, { useMemo, useState } from 'react';
import useResourceLoader from '../util/useResourceLoader';
import UWEEntityApi from '../api/UWEEntityApi';
import Loader from '../components/Loader';
import useLoader from '../util/useLoader';
import { useOpenModal } from "../components/FormModal";


const CONFIRM_ASSIGNMENT_MODAL_DEF = (user, role) => [
    "ConfirmationModal",
    `Confirmar asignación de ${role?.name}`,
    `Seguro que quiere asignar el usuario ${user.name}?`,
    [
        { text: "Si", color: "primary", value: true },
        { text: "No", color: "secondary", value: false },
    ]
];

function UWEEntityAssignments({ entity, auth }) {
    const entityId = entity?.id;

    const openModal = useOpenModal();

    const [assignmentByRoleIdMap, loadingAssignments, error, setAssignmentByRoleIdMap] = useResourceLoader(
        async () => (await UWEEntityApi.getUWEEntityAssignments({ id: entityId })).assignments.reduce((_, x) => {
            _[x.roleId] = x;
            return _;
        }, {}),
        [entityId],
        []
    );

    const [{
        roles,
        users,
        orgs,
        roleUsers,
    }, loadingRoles, errorRoles] = useResourceLoader(
        () => UWEEntityApi.getWorkflowRoles({ id: entityId }),
        [entityId],
        {}
    );

    const groupings = useMemo(() => (orgs || []).map(o => ({
        ...o,
        userIds: new Set(o.userIds)
    })), [orgs]);

    const usersById = useMemo(() => (users || []).reduce((_, user) => {
        _[user.id] = user;
        return _;
    }, {}), [users]);

    const assignments = useMemo(() => (roles || []).map(role => ({
        role,
        user: assignmentByRoleIdMap[role.id]?.user,
        userOptions: (roleUsers[role.id] || []).map(userId => usersById[userId]).filter(x => !!x)
    })), [
        roles,
        assignmentByRoleIdMap,
        usersById,
        roleUsers,
    ]);

    async function assignUser(roleId, userId) {
        const user = usersById[userId];
        if (!user) return;
        const role = roles.find(x => x.id === roleId);
        if (await openModal(...CONFIRM_ASSIGNMENT_MODAL_DEF(user, role))) {
            await UWEEntityApi.setUWEEntityAssignment({ id: entityId, roleId, userId });
            setAssignmentByRoleIdMap(assignmentByRoleIdMap => ({
                ...assignmentByRoleIdMap,
                [roleId]: {
                    ...assignmentByRoleIdMap[roleId],
                    user
                }
            }));
        }
    }

    return (loadingAssignments || loadingRoles) ? <Loader /> : (
        <>
            {assignments.map(({
                role, user, userOptions
            }, idx) => user ? (<RoleAssignmentSelect key={idx}
                roleName={role?.name}
                user={user}
                groupings={groupings}
                onChange={(userId) => assignUser(role.id, userId)}
                userOptions={userOptions}
                authUser={auth}
            />) : null)}
            {(entity?.externalData?.assignments || []).map((assignment, idx) => (
                <ExternalRoleAssignment key={idx} assignment={assignment} />
            ))}
        </>
    );
}


function RoleAssignmentSelect({
    roleName,
    user,
    userOptions,
    groupings,
    onChange: propOnChange,
    authUser
}) {
    const [loading, error, loadFn] = useLoader();
    function onChange({ target: { value } }) {
        loadFn(() => propOnChange(value));
    }

    const userInOptions = useMemo(() => user && userOptions && userOptions.some(u => u.id === user.id), [user, userOptions]);
    const userCanAssign = authUser?.user?.rolesByName[roleName]?.canAssign || false;

    return (<div>
        <label>{roleName}</label>
        {loading ? <div><Loader /></div> : (
            <select className='form-control' value={user?.id} disabled={!userCanAssign} readOnly={!userCanAssign} onChange={onChange}>
                {!user ? (<option value={0}>--------------</option>) : null}
                {(user && !userInOptions) ? <option value={user.id}>{user.name || `User #${user.id}`}</option> : null}
                {groupings ? (
                    <>
                        {groupings.map((grp, idx) => {
                            const groupUsers = (userOptions || []).filter(u => grp.userIds.has(u.id));
                            return (groupUsers.length ? (
                                <optgroup key={idx} label={grp.name}>{groupUsers.map(({ id, name }, idx) => (
                                    <option key={idx} value={id}>{name}</option>
                                ))}</optgroup>
                            ) : null);
                        })}
                        <optgroup key="ungrouped" label=" ">
                            {(userOptions || []).filter(user => !groupings.some(grp => grp.userIds.has(user.id))).map(({ id, name }, idx) => {
                                return <option key={idx} value={id}>{name}</option>
                            })}
                        </optgroup>
                    </>
                    ) : (
                    (userOptions || []).map(({ id, name }, idx) => (
                        <option key={idx} value={id}>{name}</option>
                    ))
                )}
            </select>
        )}
    </div>);
}


function ExternalRoleAssignment({assignment: {
    userType: roleName,
    fullName: name
}}) {
    return (<div>
        <label>{roleName}</label>
        <select className='form-control' value="---" disabled readOnly>
            <option value="---">{name}</option>
        </select>
    </div>);
}

export default UWEEntityAssignments;
