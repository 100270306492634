import { Trans } from '@lingui/react';
import React, { useState } from 'react';
import { useParams } from "react-router-dom";

import useResourceLoader from '../../util/useResourceLoader';
import UWEEntityApi from '../../api/UWEEntityApi';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';

import RequestReloadContext from '../../context/RequestReloadContext';

import InternalLayout from '../../components/layouts/InternalLayout';
import FormApi from '../../api/FormApi';
import UWEEntityUWEFormComponent from '../../blc/UWEEntityUWEFormComponent';

export function ViewVehicleRegistration() {
    const { id, entityType: slug } = useParams();
    const [reloadCount, setReloadCount] = useState(0);

    const [currentEntity, loadingEntity, errorLoadingEntity] = useResourceLoader(
        () => UWEEntityApi.getUWEEntity({ entityId: id }),
    [reloadCount], null);

    const [form, loadingForm, errorLoadingForm] = useResourceLoader(async () => {
        const workflowInfo = await UWEEntityApi.getWorkflowInfoForSlug({ slug });
        return FormApi.getByName({ name: workflowInfo.viewForm })
   }, [slug], {});

    const loading = loadingEntity || loadingForm;
    const error = errorLoadingEntity || errorLoadingForm;

    const rrcState = RequestReloadContext.useHook(() => {
        console.log('rrcState hook', reloadCount);
        setReloadCount(reloadCount + 1);
    }, [reloadCount]);

    return <InternalLayout>{(loading ? (
        <Loader fullscreen />
    ) : (error ? (
        <Notification color="danger">{JSON.stringify(error)}</Notification>
    ) : (!currentEntity ? (
        <Notification color="danger">Requisición no encontrada. Esto debe ser un error, por favor contacte a la administraci&oacute;n).</Notification>
    ) : (<RequestReloadContext.Provider value={rrcState}>
        <UWEEntityUWEFormComponent 
            slug={slug}
            form={form}
            currentEntity={currentEntity}
        />
    </RequestReloadContext.Provider>))))}</InternalLayout>;
}


export default ViewVehicleRegistration;