import React, { useCallback } from "react";
import { FormGroup } from "reactstrap";
import Switch from "../Switch";
import FieldTitle from "./common/FieldTitle";


function IconCheckField(props) {
    const {
        formData,
        readonly: propReadonly,
        disabled,
        idSchema: { $id },
        uiSchema: {
            'ui:readonly': uiReadonly,
            'ui:color': color='info',
            'ui:checkedColor': checkedColor,
            'ui:round': round = false
        },
        onChange: propOnChange
    } = props;

    const readonly = propReadonly || uiReadonly;

    const onChange = useCallback(({target: {checked}}) => {
        propOnChange(!!checked);
    }, []);

    const checked = !!formData;

    return (
        <FormGroup disabled={readonly || disabled}>
            <FieldTitle {...props} />
            <Switch className={`switch-${(checkedColor && checked) ? checkedColor: color}`}
                round={round}
                value={checked}
                onChange={onChange}
            />
        </FormGroup>
    );
}


export default IconCheckField;