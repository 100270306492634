import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';

export const CaspioApi = {

    async getToken() {
        return checkErrors(await fetchApi.api.get(`manage/authtoken/Caspio`));
    },

    async getMacroCategories({ token }) {
        return checkErrors(await fetchApi.api.get(`tables/ECM_LU_Category/records`, {
            "baseurl": `https://${window.PADAWAN_CONFIGURATION.cdn}.caspio.com/rest/v2/`,
            "auth": {
                "type": "Bearer",
                "token": token
            }
        }));
    },

    async getCategories({ token }) {
        return checkErrors(await fetchApi.api.get(`tables/ECM_LU_Sub_Category_1/records`, {
            "baseurl": `https://${window.PADAWAN_CONFIGURATION.cdn}.caspio.com/rest/v2/`,
            "auth": {
                "type": "Bearer",
                "token": token
            }
        }));
    },

}

export default CaspioApi;
