import { useEffect, useMemo, useState } from "react";
import IconButton from "@rjsf/core/lib/components/IconButton";
import getPathFromId from "../../util/getPathFromId";
import ComputedField from "./ComputedField";
import ErrorBoundary from "../ErrorBoundary";
import reactTraverse from "../../util/reactTraverse";
import jsonSchemas from '../../util/jsonSchemas';
import Jnx, { parseJnxExpr } from "../../util/jnx";

const ADDITIONAL_PROPERTY_FLAG = "__additional_property";
const REQUIRED_FIELD_SYMBOL = "*";


function SchemaFieldTemplate(props) {
    const {
        children,
        classNames,
        description,
        disabled,
        displayLabel,
        errors,
        formContext,
        help,
        rawErrors,
        hidden,
        id,
        label,
        onDropPropertyClick,
        onKeyChange,
        readonly,
        required: propRequired,
        registry: {
            rootSchema
        },
        schema,
        uiSchema,
    } = props;

    const {
        hidden: schemaHidden
    } = schema;

    const {
        onLocateField
    } = formContext;

    useEffect(() => {
        const visibility = formContext?.formFields?.current;
        const path = getPathFromId(id);

        if (visibility && path) {
            visibility[path] = true;

            return () => {
                visibility[path] = false;
            };
        }
    }, [id, formContext]);

    const {
        'akc:requiredIfVisible': requiredIfVisible,
        'akc:requiredIf': requiredIfExpr,
        'ui:labelSuffix': labelSuffix,
        'ui:hideLabel': hideLabel,
        'ui:field': uiField
    } = uiSchema;

    const required = requiredIfVisible || propRequired || !!(requiredIfExpr);
    const shouldHide = uiField !== "ComputedField";
    if(schemaHidden && shouldHide){
        return null;
    }
    if (hidden) {
        return <div className="hidden">{children}</div>;
    }

    const field = (<ErrorBoundary>
        {displayLabel && !hideLabel && <>
            <Label label={label} suffix={labelSuffix} required={required} id={id} />
            {help || null}
            {schema?.description ? description : null}
        </>}
        {children}
        {rawErrors?.length ? errors : null}
    </ErrorBoundary>);

    const [isHighlighthed, setHighlighthed] = useState(0);
    const hasAdditional = !!schema[ADDITIONAL_PROPERTY_FLAG];
    const fieldLocator = useMemo(() => {
        return onLocateField ? ({
            onMouseEnter(e) {
                setHighlighthed(h => h + 1);
            },
            onMouseLeave(e) {
                setHighlighthed(h => h - 1);
            },
            onClick(e) {
                const topmostSftNode = reactTraverse(e.target).filter(fn => fn.elementType?.name === SchemaFieldTemplate.name).pop();
                onLocateField(topmostSftNode.memoizedProps.id);
            }
        }) : ({})
    }, [onLocateField, setHighlighthed]);
    useEffect(() => {
        if(!onLocateField) setHighlighthed(0);
    }, [onLocateField])

    return <div
        className={`${classNames || ''} ${(isHighlighthed > 0) ? 'is-highligthed' : ''}`}
        {...fieldLocator}
    >{hasAdditional ? (<div className="row">
        <div className="col-xs-5 form-additional"><FieldKeyLabel {...props} /></div>
        <div className="form-additional form-group col-xs-5">{field}</div>
        <div className="col-xs-2"><IconButton
            type="danger" icon="remove"
            className="array-item-remove btn-block"
            tabIndex="-1"
            style={{ border: "0" }}
            disabled={disabled || readonly}
            onClick={onDropPropertyClick(label)}
        /></div>
    </div>) : (
        field
    )}</div>;
}


function FieldKeyLabel({ id, label, onKeyChange, required }) {
    const keyLabel = 'Key'; // i18n ?
    const keyId = `${id}-key`;

    return (<div className="form-group">
        <Label label={keyLabel} required={required} id={keyId} data-cy={keyId} />
        <LabelInput label={label} required={required} id={keyId} data-cy={keyId} onChange={onKeyChange} />
    </div>);
}


function Label({ label, suffix, required, id }) {
    return label ? (
        <label className="control-label" data-cy={id} htmlFor={id}>
            {label}{suffix || null}
            {required && <span className="required">{REQUIRED_FIELD_SYMBOL}</span>}
        </label>
    ) : null;
}


function LabelInput({ id, label, onChange }) {
    return (<input
        className="form-control" type="text"
        data-cy={id}
        id={id} onBlur={event => onChange(event.target.value)}
        defaultValue={label}
    />);
}


export default SchemaFieldTemplate;