import React, { useContext } from 'react';
import { Container } from 'reactstrap';
import AppContext from '../../context/AppContext';
import { AuthContext } from '../../context/AuthContext';
import NavMenu from '../NavMenu';
import Sidebar from '../Sidebar';


function InternalLayout({
  children,
  tabs, activeTab, setActiveTab,
}) {
  const {user} = useContext(AuthContext);
  const {sidebarExpanded} = useContext(AppContext);
  const versionTag = window.APP_VERSION;
  return (
    <div className={`app-layout ${sidebarExpanded ? 'has-sidebar-tab-open' : ''}`}>
        <NavMenu />
        <div className='app-body'>
            {user ? <Sidebar
              propTabs={tabs} propActiveTab={activeTab} propSetActiveTab={setActiveTab}
            /> : null}
            <Container className="main-container" fluid>
              {children}
            </Container>
        </div>
      {versionTag ? (<div className="version-tag">{versionTag}</div>) : null}
    </div>
  );
}


export default InternalLayout;