import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { Nav, NavItem, NavLink, TabContent, TabPane, Button } from "reactstrap";
import WorkflowApi from "../../../api/WorkflowApi";
import useActionReducer from "../../../util/useActionReducer";
import useResourceLoader from "../../../util/useResourceLoader";
import useSearchParams from "../../../util/useSearchParams";
import workflowDiagramActions from "./actions";
import WorkflowDiagram from "./WorkflowDiagram";
import WorkflowJson from "./WorkflowJson";
import { LABEL } from '../../../LanguageConstant';
import useLoader from "../../../util/useLoader";
import Loader from "../../../components/Loader"
import Notification from "../../../components/Notification";
import useTitle from "../../../util/useTitle";

// import SLASetting from "./SLASetting";
// import Forms from "./Forms";
// import UserTable from "../users/UsersTable";
// import OrganizationalUnitsTable from "./OrganizationalUnit/OrganizationalUnitsTable";


const TABS = {
    // 'list': {title: 'List', component: ListWorkflows},
    'diagram': { title: 'Diagram', component: WorkflowDiagram },
    'json': { title: 'Json', component: WorkflowJson },
}

const DEFAULT_TAB = Object.keys(TABS)[0];

const MAKE_NEW_WORKFLOW = () => (
    {
        definition: {},
        name: "new-workflow",
        paramSchema: {},
        roles: []
    });

function WorkflowIndex({ workflowId, setWorkflow: setWorkflowId }) {

    const [workflow, loadingWorkflow, errorLoadingWorkflow] = useResourceLoader(() => (
        workflowId === "new" ? MAKE_NEW_WORKFLOW : WorkflowApi.get({ workflowId })
    ), [workflowId]);

    const [loadingSetWorkflow, errorLoadingSetWorkflow, loadFn] = useLoader();

    const [
        [tab, setTab]
    ] = useSearchParams({
        tab: DEFAULT_TAB
    });

    useTitle(workflow ? (
        workflowId === 'new' ? 'New Workflow': `Edit Workflow | ${workflow.name}`
    ) : 'Loading');

    const [state, dispatchAction] = useActionReducer(workflowDiagramActions, {
        steps: {},
        arrows: []
    });

    useEffect(() => {
        if (workflow) {
            dispatchAction.setWorkflow(workflow);
        }
    }, [workflow]);

    function save() {
        const wf = {
            ...workflow,
            definition: {...state.steps},
            roles: state.roles,
            name: state.name,
            objectSchemaId: state.objectSchemaId,
            ...state.details,
            paramSchema: state.paramSchema
        }
        if (workflowId === "new") {
            loadFn(() => WorkflowApi.create(wf).then(workflow => {
                setWorkflowId(workflow.data.id)
            }));
        }
        else {
            loadFn(() => WorkflowApi.update(wf.id, wf));
        }
    }

    const loading = loadingWorkflow || loadingSetWorkflow;
    const error = errorLoadingWorkflow || errorLoadingSetWorkflow;

    return <div>
        <Nav tabs>{Object.entries(TABS).map(([key, { title }]) => (
            <NavItem key={key} onClick={() => setTab(key)}>
                <NavLink active={tab === key}>{title}</NavLink>
            </NavItem>
        ))}</Nav>
        {error ? <Notification error={error} /> : null}
        {loading ? <Loader fullscreen /> : (
            <TabContent activeTab={tab}>{Object.entries(TABS).map(([key, { component: Component }]) => (
                <TabPane key={key} tabId={key}>{
                    tab === key ? <Component workflow={workflow} state={state} dispatchAction={dispatchAction} /> : null
                }</TabPane>
            ))}</TabContent>
        )}
        <Button color="success" onClick={save}>{LABEL.save}</Button>
    </div>
}


export default WorkflowIndex;