export const TYPES = ["string", "number", "object", "array", "boolean", "null"];
export const [TYPE_STRING, TYPE_NUMBER, TYPE_OBJECT, TYPE_ARRAY, TYPE_BOOLEAN, TYPE_NULL] = TYPES

export const JS_JSON_TYPEMAP = {
    "undefined": TYPE_NULL,
    "boolean"  : TYPE_BOOLEAN,
    "number"   : TYPE_NUMBER,
    "bigint"   : TYPE_NUMBER,
    "string"   : TYPE_STRING,
    "symbol"   : TYPE_STRING,
    "function" : TYPE_STRING,
}

export const CAST_TO = {
    [TYPE_STRING ] : x => `${x}`,
    [TYPE_NUMBER ] : x => Number.isFinite(+x) ? +x : 0,
    [TYPE_OBJECT ] : x => ({}),
    [TYPE_ARRAY  ] : x => [],
    [TYPE_BOOLEAN] : x => !!x,
    [TYPE_NULL   ] : x => null,
}

export function determineJSONType(value) {
    const valueType = typeof(value);
    const mappedType = JS_JSON_TYPEMAP[valueType];
    if (mappedType) return mappedType;
    if (valueType === "object") return value ? (Array.isArray(value) ? TYPE_ARRAY : TYPE_OBJECT) : TYPE_NULL;
    return TYPE_STRING;
}

export const EDITORS = {
    [TYPE_STRING ] : () => `${TYPE_STRING} editor`,
    [TYPE_NUMBER ] : () => `${TYPE_NUMBER} editor`,
    [TYPE_OBJECT ] : () => `${TYPE_OBJECT} editor`,
    [TYPE_ARRAY  ] : () => `${TYPE_ARRAY} editor`,
    [TYPE_BOOLEAN] : () => `${TYPE_BOOLEAN} editor`,
    [TYPE_NULL   ] : () => `${TYPE_NULL} editor`,
}