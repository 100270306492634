import { t } from "@lingui/macro";
import Dashboard from "../../../components/Dashboard/index";
// import DocumentApi from "../../../api/DocumentApi";


const ListDocuments = Dashboard.HOC({
    schema: {
        "title": t`Document`,
        "type": "document",
        "elements": [{
            "title": t`Documents Pending Generation`,
            "type": "worktray",
            ":className": "col-lg-12",
            "resource": "manage/documents/pending",
            "tableActions": [{ "type": "reload" }],
            "columns": [
                { "title": t`Id`, "sortkey": "id", "map:field": "id" },
                { "title": t`Event`, "sortkey": "event", "map:field": "event" },
                { "title": t`Status`, "sortkey": "generationStatus", "map:field": "generationStatus" },
                { "title": t`Last Update`, "sortkey": "lastUpdate", "map:field": "lastUpdate", "format": "date" },
                // {
                //     "title": t`Actions`, "actions": [
                //         {
                //             "label": t`Rerun`,
                //             "requireExpr": "(status ~> $lowercase) in ['failed']",
                //             "onClick": (row, { loadFn, reload }) => {
                //                 return loadFn(async () => {
                //                     await DocumentApi.rerunJob({ jobId: row.id });
                //                     reload();
                //                 })
                //             },
                //         }, {
                //             "label": t`Hide`,
                //             "requireExpr": "(status ~> $lowercase) in ['failed', 'stuck']",
                //             "onClick": (row, { loadFn, reload }) => {
                //                 return loadFn(async () => {
                //                     await DocumentApi.hideJob({ jobId: row.id });
                //                     reload();
                //                 })
                //             },
                //         }
                //     ]
                // },
            ]
        }]
    }
});


export default ListDocuments;