import { t } from "@lingui/macro";
import React, { useMemo } from "react";
import Dashboard from "../../../components/Dashboard/index";


function ListInvoiceItem(){
    const schema = useMemo(() => ({
        "type": "dashboard",
        "elements": [{
            "title": t`Invoice Items`,
            "type": "worktray",
            ":className": "col-lg-12",
            "resource": "manage/invoice",
            "tableActions": [{
                "label": t`Create Item`,
                "route": "/invoice/create",
            }],
            "filters": [
                { "title": t`Code`, "key": "code", "type": "string" },
                { "title": t`Description`, "key": "description", "type": "string" },
            ],
            "columns": [
                { "title": t`Code`, "sortkey": "code", "map:field": "code" },
                { "title": t`Description`, "sortkey": "description", "map:field": "description" },
                { "title": t`Price`, "sortkey": "price", "map:field": "price" },
                { "title": t`Actions`, "actions": [{
                    "label": t`Edit`,
                    "route": "/invoice/$id",
                } ] },
            ]
        }]
    }), []);

    return <Dashboard schema={schema} />;
}

export default ListInvoiceItem;