import React, { useRef, useState, useCallback } from "react";
import { FormGroup, Button } from "reactstrap";
import SignatureCanvas from "react-signature-canvas";
import BrowserImageManipulation from 'browser-image-manipulation'


function InteractiveDrawing(props) {

    const {
        formData,
        title,
        background,
        classNames,
        penColor,
        $id,
        required,
        ref,
        clearSignature,
        onChange,
        width, height,
        isDisabled,
    } = useInteractiveDrawingHooks(props);

    if (ref.current && !formData) {
        ref.current.fromDataURL(background);
    }

    return (
        <FormGroup disabled={isDisabled}>
            <div>
                <label className="control-label" htmlFor={$id}>
                    {title}{required ? <span className="required">*</span> : null}
                </label>
                <div className={`${classNames || ''} form-control interactive-drawing`} style={{
                    width: `${width}px`,
                    height: `${height}px`
                }}>
                    {isDisabled ?
                        <img src={formData} alt="firma" style={{
                            width: `${width}px`,
                            height: `${height}px`,
                        }} />
                        :
                        <SignatureCanvas
                            ref={ref}
                            penColor={penColor}
                            canvasProps={{ className: "sign signature-pad writable" }}
                            disabled={isDisabled}
                            required={required}
                            onEnd={onChange}
                            value={formData}
                            backgroundColor="white"
                        />
                    }
                    <div className="button-bar tr">
                        {!isDisabled ?
                            <Button onClick={clearSignature} disabled={isDisabled} title="Clear Signature">
                                <i className="fa fa-trash" />
                            </Button> : null}
                    </div>
                </div>
            </div>
        </FormGroup>
    );
}


function useInteractiveDrawingHooks(props) {

    const {
        formData,
        disabled,
        schema: {
            title,
            background,
        },
        uiSchema: {
            'ui:classNames': classNames,
            'ui:readonly': readonly,
            size = ['100%', 300],
            penColor = 'red',
        },
        idSchema: { $id },
        // title,
        required,
        onChange: propOnChange
    } = props;

    const ref = useRef();

    const [width, height] = size;
    const isDisabled = readonly || disabled;

    return {
        formData,
        title,
        background,
        classNames,
        penColor,
        $id,
        required,
        ref,
        clearSignature() {
            ref.current.clear();
            ref.current.fromDataURL(background);
        },
        async onChange() {
            const data = ref.current.toDataURL("image/png")
            const blob = await fetch(data).then((res) => { return res.blob() });
            new BrowserImageManipulation()
                .loadBlob(blob)
                .resize(width, height)
                .saveAsImage()
                .then(base64 => {
                    console.log(base64)
                    propOnChange(base64);
                })
                .catch(e => console.error(e.toString()))
        },
        attachSignature(data) {
            propOnChange(data);
        },
        width,
        height,
        isDisabled
    };
}

export default InteractiveDrawing;