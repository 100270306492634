import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';

const ReportsApi = {
    async getReferenceId({ type, dateTo, dateFrom }) {
        return (await checkErrors(await fetchApi.api.get(`uwe-entities/reports/${type}/${dateFrom}/${dateTo}`))).data;
    },
}

export default ReportsApi;
