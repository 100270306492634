import { t } from "@lingui/macro";
import React, { useMemo } from "react";
import Dashboard from "../../../components/Dashboard/index";


function ListForms() {
    const schema = useMemo(() => ({
        "title": t`Forms`,
        "type": "dashboard",
        "elements": [{
            "title": t`Forms`,
            "type": "worktray",
            ":className": "col-lg-12",
            "resource": "forms",
            "tableActions": [{
                "label": t`Create Form`,
                "route": "?form=new",
            }],
            "filters": [
                { "title": t`Name`, "key": "name", "type": "string" },
                { "title": t`Title`, "key": "title", "type": "string" },
                {
                    "title": t`Entity Type`, "key": "entityType", "type": "number",
                    "ui:field": "LookupFormField",
                    "lookup": {
                        "resource": t`Entity Types`,
                        "api": "api:manage/objectschemas",
                        "params": { "all": true },
                        "options": { "useCache": true },
                        "jnx": "data.items",
                        "id": "id",
                        "label": "name"
                    }
                },
            ],
            "columns": [
                { "title": t`Name`, "sortkey": "name", "map:field": "name" },
                { "title": t`Title`, "sortkey": "title", "map:field": "title" },
                { "title": t`Entity Type`, "sortkey": "entityType", "map:field": "objectSchema" },
                { "title": t`Version`, "sortkey": "version", "map:field": "version" },
                { "title": t`Last Updated`, "sortkey": "lastUpdated", "map:field": "lastUpdated", "format": "date" },
                {
                    "title": t`Actions`, "actions": [{
                        "label": t`Edit`,
                        "route": "?form=$id",
                    },
                    {
                        "label": t`Duplicate`,
                        "type": "ConfirmButton",
                        "confirm": {
                            "text": "Está seguro que desea duplicar el Formulario?",
                            "api": "forms/$id/duplicate",
                            "method": "get"
                        }
                    },
                    {
                        "label": t`Delete`,
                        "type": "ConfirmButton",
                        "confirm": {
                            "text": "Está seguro que desea eliminar el Formulario?",
                            "api": "forms/$id",
                            "method": "delete"
                        }
                    }]
                },
            ]
        }]
    }), []);

    return <Dashboard schema={schema} />;
}

export default ListForms;