import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
// import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";
import { lookupOptions } from "./hooks/useOptionsLookup";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import getPathFromId from '../../util/getPathFromId';
import { useJnx } from "../../util/jnx";


function Autocomplete(props) {
    const {
        schema: {
            title,
            lookup,
            type
        },
        formContext,
        formContext: {
            setFormDataValues,
            sideChannel
        },
        value,
        formData,
        onChange,
        disabled,
        required,
        idSchema: { $id } = {},
        uiSchema: {
            'ui:readonly': uiReadonly,
            'ui:readonlyIf': uiReadonlyIf,
        },
        readonly: propReadonly
    } = props;
    const {
        resource = "Options",
        jnx: apiResponseJnxExpr,
        defaultValueExpr,
        onSelectExpr,
        params = {},
        api,
        id,
        label: labelExpr,
        searchParam,
        setObjectAs,
        allowNoValue = true,
        noValue = ''
    } = lookup || {};

    const promiseRef = useRef();


    const rootFormData = useSideChannelSubscription(sideChannel, 0);

    const path = useMemo(() => getPathFromId($id), [$id]);

    const functionBinds = useMemo(() => ({ set: setFormDataValues }), [setFormDataValues]);

    const onDefaultValue = useJnx(defaultValueExpr, {functionBinds});
    const defaultValue = useMemo(() => onDefaultValue?.eval(
        rootFormData || {}, path, {
        formData, rootFormData,
        formContext
    }
    ), [onDefaultValue, defaultValueExpr, formData, rootFormData, path]);

    const labelJnx = useJnx(labelExpr);

    const onSelectJnx = useJnx(onSelectExpr, {functionBinds});
    const label = useMemo(() => onSelectJnx?.eval(
        rootFormData || {}, path, {
        formData, rootFormData,
        formContext
    }
    ), [onSelectJnx, formData, rootFormData, path]);

    const [current, setCurrent] = useState(value);
    useDebouncedEffect(() => {
        if (current !== value) {
            onChange(current.value)
        }
    }, [current], 450);
    useEffect(() => setCurrent(value), [value]);


    const readonlyIfJnx = useJnx(uiReadonlyIf);
    const readOnlyIf = useMemo(() => (
        readonlyIfJnx && readonlyIfJnx.eval(rootFormData || {}, '', {
            root: rootFormData,
            formContext,
        })
    ), [value, rootFormData, formContext]);

    const readonly = uiReadonly || propReadonly || readOnlyIf;

    const promiseOptions = useCallback((inputValue, isQR = false) => {
        const searchValue = inputValue.trim();
        if (searchValue.length > 2) {
            const search = { searchParam };
            search.promise = new Promise((resolve) => {
                search.resolve = resolve;
            });
            promiseRef.current = search.promise;
            setTimeout(async () => {
                if (promiseRef.current === search.promise) {
                    const options = await lookupOptions({ ...lookup, params: { ...lookup.params, [searchParam]: searchValue } }, rootFormData);
                    if (isQR) {
                        const obj = options?.[0];
                        setCurrent({ value: obj[id], label: labelJnx.eval(obj) });
                        search.resolve([{
                            value: obj[id],
                            label: labelJnx.eval(obj)
                        }]);
                    } else {
                        const dataParse = (options || []).map((obj) => {
                            return {
                                value: obj[id],
                                label: labelJnx.eval(obj)
                            };
                        });
                        search.resolve(dataParse);
                    }
                }
            }, 300);
            return search.promise;
        }
        return Promise.resolve([]);
    }, [rootFormData, searchParam, lookupOptions, id, labelJnx]);

    useEffect(() => {
        if (label) {
            promiseOptions(label, true);
        }
    }, [label, onSelectJnx, promiseOptions]);

    return (
        <div className="autocomplete" data-cy={$id}>
            {title.trim() ? (<label className="control-label" >
                {title}{required ? <span className="required">*</span> : null}
            </label>) : null}
            <AsyncSelect
                required={required}
                isDisabled={readonly || disabled}
                cacheOptions
                defaultOptions
                value={current || defaultValue}
                loadOptions={promiseOptions}
                onChange={(value) => setCurrent(value)}
            />
        </div>
    )
}

export default Autocomplete;