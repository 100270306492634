import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';


export const InvoiceItemApi = {
    async createItem(data) {
        return checkErrors(await fetchApi.api.post('manage/invoice/', { data }));
    },

    async getItem({ id }) {
        return (await checkErrors(await fetchApi.api.get(`manage/invoice/${id}`))).data;
    },

    async editItem({ id, ...data }) {
        return checkErrors(await fetchApi.api.post(`manage/invoice/${id}`, { data }));
    },

}


export default InvoiceItemApi;
