import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";

const PARAMS = ['contractId'];

function getItemProduct() {
    return JSON.parse(localStorage.getItem('products'));
}

function getContract() {
    return JSON.parse(localStorage.getItem('contract'));
}

function useItemProduct() {
    const [itemProduct, setItemProduct] = useState(getItemProduct());
    const [contract, setContract] = useState(getContract());

    useEffect(() => {
        function handleChangeStorage() {
            setItemProduct(getItemProduct());
            setContract(getContract());
        }

        window.addEventListener('storage', handleChangeStorage);
        return () => window.removeEventListener('storage', handleChangeStorage);
    }, []);

    return { itemProduct, contract, setContract, setItemProduct };
}

function CatalogModal({ contractId, onCancel, onConfirm, isOpen }) {

    const SOURCE = "./catalog/public/index.html?cbResetParam=1";
    const url = contractId ? `${SOURCE}&contract=${contractId}` : SOURCE;

    console.log("CONTRACT", url)

    const { itemProduct, contract, setContract, setItemProduct } = useItemProduct();
    const [showButtonContinue, setShowButtonContinue] = useState(false);
    const [keyModal, setKeyModal] = useState(0);
    const [itemWithoutContract, setItemWithoutContract] = useState({ quantity: 1, category: '', subcategory1: '' });
    const [itemBlankWithContract, setItemBlankWithContract] = useState({ quantity: 1, category: '', subcategory1: '', contract: '', fromCatalog: true });

    function bindEvent(element, eventName, eventHandler) {
        if (element.addEventListener) {
            element.addEventListener(eventName, eventHandler, false);
        } else if (element.attachEvent) {
            element.attachEvent(`on${eventName}`, eventHandler);
        }
    }

    useEffect(() => {

        bindEvent(window, 'message', (e) => {


            if (typeof e.data === "object" && e.data?.item && e.data.item) {

                if (e.data.item.category && e.data.item.subcategory && e.data.item?.countContracts === 1) {

                    setItemWithoutContract(prevState => ({
                        ...prevState,
                        category: e.data.item.category,
                        subcategory1: e.data.item.subcategory,
                        categoryId: e.data.item.categoryId,
                        subcategory1Id: e.data.item.subcategoryId,
                    }));

                    setShowButtonContinue(true);
                    return null;
                } else if (e.data.item.category && e.data.item.subcategory && e.data.item.contract) {

                    setItemBlankWithContract(prevState => ({
                        ...prevState,
                        category: e.data.item.category,
                        subcategory1: e.data.item.subcategory,
                        categoryId: e.data.item.categoryId,
                        subcategory1Id: e.data.item.subcategoryId,
                        contract: e.data.item.contract,
                    }));

                    setShowButtonContinue(false);
                    return null;
                }
                else {
                    setShowButtonContinue(false);
                    return null;
                }
            }

            return null;
        });

    }, []);

    const resetContract = () => {
        window.localStorage.removeItem('contract');
        window.localStorage.removeItem('products');
        setContract("");
        setItemProduct([]);
        setKeyModal((value) => value + 1);
    };

    return (
        <Modal isOpen={isOpen}
            toggle={onCancel}
            size='xl'
            backdrop='static'
        >
            <ModalBody style={{ padding: 0 }}>
                <iframe key={keyModal} title="catalog" src={url} height={530} width='100%' style={{ border: 0 }} />
            </ModalBody>
            <ModalFooter>
                <div className="col-lg-12">
                    <Button className="float-left mr-3" onClick={onCancel} variant="secondary">Cerrar</Button>
                    {!contractId && ((contract && (!itemProduct || (itemProduct && itemProduct.length === 0)))) && <Button className="float-left" onClick={resetContract} color="warning">Cambiar de Contrato</Button>}
                    {itemProduct && itemProduct.length > 0 && <Button className="float-right" onClick={() => { onConfirm(itemProduct, contract) }} color="primary">Continuar</Button>}
                    {contract && (!itemProduct || (itemProduct && itemProduct.length === 0)) && itemBlankWithContract.contract && <Button className="float-right" onClick={() => { onConfirm([itemBlankWithContract], contract) }} color="primary">Continuar y completar items de forma manual</Button>}
                    {showButtonContinue && <Button className="float-right" onClick={() => { onConfirm([itemWithoutContract], contract) }} color="primary">Continuar y completar items de forma manual sin contrato</Button>}
                </div>
            </ModalFooter>
        </Modal>
    );
}

CatalogModal.PARAMS = PARAMS;



export default CatalogModal;