import { useState, useEffect } from 'react';

/** Hook for loading a resource.
 * Loads a resource as an effect whenever the dependencies change.
 * 
 * @param {Function} resourceFn - async function returning the resource
 * @param {Array} dependencies - List of dependencies. The function is called whenever an item changes in this list.
 * @param {Object} initialValue - Initial resource value
 * 
 */
function useResourceLoader(resourceFn, dependencies, initialValue) {
    const [resource, setResource] = useState(initialValue);
    const [loading, setLoading] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        setError();
        const flags = {canceled: false};
        Promise.resolve().then(
            resourceFn
        ).then((newResource) => {
            if(flags.canceled) return;
            if (newResource !== useResourceLoader.DoNotSet && newResource !== resource) {
                setResource(newResource);
            }
            setLoading(false);
        }).catch(err => {
            if(flags.canceled) return;
            setLoading(false);
            setError(err);
        });
        return () => {flags.canceled = true;}
    }, dependencies);

    return [resource, loading, error, setResource];
}

useResourceLoader.DoNotSet = {};

export default useResourceLoader;
