import { t } from "@lingui/macro"

export const LABEL = {
        users: t`User`,
        edit: t`Edit`,
        save: t`Save`,
        createForm: t`Create Form`,
        createWorkflow: t`Create Workflow`,
        agencies: t`Agencies`,
        objectTypes: t`Entity Types`,
        invoiceItems: t`Invoice Items`,
        workflow: t`Workflow`,
        forms: t`Forms`,
        sla: t`SLA`,
        integrations: t`Integrations`,
        userRegister: t`Add User`,
        agencyRegister: t`Add Agency`,
        help: t`Help`,
        workTray: t`Work Tray`,
        selectComponent: t`Select a component of the system you will manage`,
        archived: t`Archived`,
        name: t`Name`,
        organization: t`Organization`,
        position: t`Position`,
        active: t`Active`,
        code: t`Code`,
        city: t`City`,
        postalCode: t`Postal Code`,
        state: t`State`,
        modifyComponent: t`Modify Component`,
        sections: t`Sections`,
        createSection: t`Create Section`,
        createObject: t`Create Object`,
        type: t`Type`,
        subtype: t`Sub Type`,
        title: t`Title`,
        enum: t`Options`,
        format: t`Format`,
        uiPlaceHolder: t`Placeholder`,
        classNames: t`Styles`,
        uiReadonly: t`Readonly`,
        minLength: t`Min. Length`,
        maxLength: t`Max. Length`,
        uiPrefix: t`Prefix`,
        uiSufix: t`Sufix`,
        uiNewSection: t`New Section`,
        uiExpandable: t`Expandable`,
        akcRequiredIfVisible: t`Required`,
        uiCellClassName: t`Cell Class`,
        uiHideTitle: t`Hide Title`,
        uiShowheaderOnEmpty: t`Show Header On Empty`,
        uiAddable: t`Addable`,
        uiRemovable: t`Removable`,
        uiRemovableLabelButton: t`Remove Button Label`,
        uiRemovableConfirm: t`Confirm on Remove?`,
        uiRemovableConfirmText: t`Confirm on Remove Text`,
        uiOrderable: t`Orderable`,
        addOption: t`Add Option`,
        mask: t`Mask`,
        uiPositionButtons: t`Position Buttons`,
        uiAddableLabelButton: t`Add Button Label`,
        uiSimulateLabel: t`Simulate Label`,
        akcDisableSpaces: t`Disable Spaces`,
        uiExtensions: t`Accept Extensions`,
        akcIntegerOnly: t`Integer Only`,
        akcNumbersOnly: t`Numbers Only`,
        akcLettersOnly: t`Letters Only`,
        akcNoSpecialChar: t`No Special Characters`,
};