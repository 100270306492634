import dfsSearch from "./dfsSearch";
import { setObject } from "./mapObject";


function parseUISchemaFromSchema(schema, uiSchema) {
    const flattenedUiSchema = {};

    dfsSearch([['', schema]], ([path, current], {stack}) => {
        const pathPrefix = path.length ? `${path}.` : '';

        Object.entries(current || {}).forEach(([attr, value]) => {
            if (attr.startsWith("ui:") || attr.startsWith("akc:")) {
                flattenedUiSchema[`${pathPrefix}${attr}`] = value;
            } else if (attr.startsWith(":")) {
                flattenedUiSchema[`${pathPrefix}${attr.substr(1)}`] = value;
            }
        });

        switch (current?.type) {
            case "object":
                {
                const resultUiShowIf = Object.values(current.properties).some(
                    (prop) => prop["ui:showIf"]
                );

                if(resultUiShowIf){
                    flattenedUiSchema[`${pathPrefix}ui:field`] = "HideObjectField";
                }

                stack.push(...Object.entries(current.properties).map(
                    ([prop, propSchema]) => [`${pathPrefix}${prop}`, propSchema]
                ));
                if (current.additionalProperties) {
                    stack.push([`${pathPrefix}additionalProperties`, current.additionalProperties]);
                }
            }
            break;
            case "array":
                stack.push([`${pathPrefix}items`, current.items]);
            break;
            default: break;
        }
    });

    const resultUiSchema = uiSchema ? JSON.parse(JSON.stringify(uiSchema)) : {};
    Object.entries(flattenedUiSchema).forEach(([path, value]) => {
        setObject(resultUiSchema, path, value);
    });

    return resultUiSchema;
}

export default parseUISchemaFromSchema;