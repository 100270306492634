import React from "react";

import SidebarComponents from './SidebarComponents';
import UWEFormComponent from "./UWEFormComponent";


function UWEEntityUWEFormComponent({
    slug,
    form,
    currentEntity,
}) {


    return (
        <SidebarComponents currentEntity={currentEntity} title={form?.title} onComponents={form?.sidebarComponents}>
            <UWEFormComponent currentEntity={currentEntity} form={form} slug={slug} />
        </SidebarComponents>
    );
}


export default UWEEntityUWEFormComponent;