import React from "react";
import useSearchParams from "../../../util/useSearchParams";
import EditSequence from "./EditSequence";
import ListSequence from "./ListSequence";


function Sequences() {
    const [
        [sequence, setSequence]
    ] = useSearchParams({
        sequence: undefined
    });

    return sequence ? (
        <EditSequence sequenceId={sequence} setSequence={setSequence} />
    ) : (
        <ListSequence setSequence={setSequence} />
    );
}

export default Sequences;