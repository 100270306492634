import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';


export const FormApi = {
    async list({ offset, size, sortBy }) {
        return checkErrors(await fetchApi.api.get('forms', { query: { offset, size, sortBy } }));
    },

    async get({ formId }) {
        return checkErrors(await fetchApi.api.get(`forms/${formId}`));
    },

    async getForms() {
        return checkErrors(await fetchApi.api.get('forms/all'));
    },

    async getByName({ name }) {
        return checkErrors(await fetchApi.api.get(`forms/named/${name}`));
    },

    async getByNameForAnonymous({ name, slug, tenant }) {
        return checkErrors(await fetchApi.api.get(`anon/${tenant}/workflows/${slug}/forms/named/${name}`));
    },

    async update(formId, form) {
         return (await checkErrors(await fetchApi.api.put(`forms/${formId}`,{data: form}))).data;
    },
}


export default FormApi;
