import React, { useEffect } from 'react';
import useOptionsLookup from "../customFields/hooks/useOptionsLookup";

function EntityWidget(props) {
    const { element, scope } = props;
    const { lookup } = element;
    const { entity } = lookup;

    const {
        options: lookupOptions, loadingOptions, errorLoadingOptions
    } = useOptionsLookup({
        lookup,
        scope,
    });

    useEffect(() => {
        if (lookupOptions && lookupOptions?.[0]) {
            scope.setdashboardData([entity], lookupOptions[0])
        }
    }, [entity, lookupOptions])

    return null;
}

export default EntityWidget;