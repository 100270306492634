import { DASHBOARD_COMPONENTS } from '../../../components/Dashboard/index';
import FormComponent from '../../../components/FormComponent';
import parseFormDefinition from '../../../util/parseFormDefinition';


export const DashboardDetailsEditor = FormComponent({
    ...parseFormDefinition({
        displayName: 'DashboardDetailsEditor',
        title: "Dashboard Details",
        schemaProps: {
            "title": "Dashboard ${imagename.name}",
            "ui:newSection": true,
            ":classNames": "grid-2-columns",
            "definitions": {
                "elementType": {
                    "type": "string",
                    "enum": Object.keys(DASHBOARD_COMPONENTS)
                }
            }
        },
        schema: {
            // type: "object",
            "order": {
                "title": "Order",
                "map:field": "order",
                "type": "number",
                "structure": "Number"
            },
            "imagename": {
                ":classNames": "from-col-1-size-1 flex-h",
                "type": "object",
                "title": " ",
                "properties": {
                    "image": {
                        "title": "Image",
                        "map:field": "image",
                        "ui:field": "SelectImageField",
                        "type": "object",
                        "properties": {},
                        "structure": "Object"
                    },
                    "name": {
                        "title": "Name",
                        "map:field": "name",
                        "type": "string",
                        "structure": "Text"
                    }
                }
            },
            "slug": {
                "title": "Slug",
                "map:field": "slug",
                "type": "string",
                "structure": "Text"
            },
            "elements": {
                ":classNames": "from-col-1-size-2",
                "title": "Elements",
                "map:field": "definition.elements",
                "type": "array",
                "ui:arrayType": "cards",
                "items": {
                    "type": "object",
                    "properties": {},
                    "ui:field": "SubformField",
                    "schemaExpr": [
                        "$deepMerge(",
                        "    $schema.schema,",
                        "    $coalesce($schema.byType ~> $lookup(type), $schema.byType.default)",
                        ")"
                    ],
                    "byType": {
                        ...Object.entries(DASHBOARD_COMPONENTS).reduce((_, [k, {rootSchema}]) => {
                            if (rootSchema) _[k] = rootSchema;
                            return _;
                        }, {}),
                        "default": {
                            "type": "object",
                            "properties": {
                                "json": {
                                    "type": "object",
                                    "properties": {},
                                    "map:field": "",
                                    "ui:field": "JsonField"
                                }
                            }
                        }
                    },
                    "schema": {
                        "type": "object",
                        "title": "Element # ${$index + 1} (${type} ${col} ${colSpan})",
                        "ui:newSection": true,
                        "ui:expandable": true,
                        ":classNames": "grid-3-columns",
                        "properties": {
                            "type": { "$ref": "#/definitions/elementType", "map:field": "type" },
                            "col": {
                                "type": "number",
                                "title": "Column",
                                "ui:placeholder": "auto-assigned",
                                "map:field": "col"
                            },
                            "colSpan": {
                                "type": "number",
                                "title": "Column Span",
                                "default": 4,
                                "min": 1,
                                "max": 4,
                                "map:field": "colSpan"
                            },
                        },
                    }
                }
            }
        },
    }),
    withoutNavigation: true,
    parseProps: ({ dashboard, onChange }) => ({
        dashboard,
        onDashboardChange: onChange
    }),
    loadData: ({ dashboard }) => dashboard,
    hideDataLoader: true,
    onChange({
        formObject,
        parsedProps: {
            onDashboardChange
        }
    }) {
        if (!formObject) return;
        onDashboardChange(formObject);
    },
    onSubmit: ({ formData }) => Promise.resolve(),
    submitButtons: {}
});

export default DashboardDetailsEditor;
