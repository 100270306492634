import React, { useState, useMemo, useEffect } from 'react';
import { Button, List, Card, CardHeader, CardBody } from 'reactstrap';
import _ from 'lodash';
import formErrors from '../locales/es/formErrors';

import deduplicate from '../util/deduplicate';
import {ERRORS_KEY} from '../util/formValidation';
import dfsSearch from '../util/dfsSearch';
import jsonSchemas from '../util/jsonSchemas';


function ErrorListTemplate({
    errorSchema,
    errors: propErrors,
    schema,
    uiSchema
}) {
    const [expanded, setExpanded] = useState(1);
    function toggleExpand(e){
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setExpanded(!expanded);
    }


    const errorDataByField = useMemo(() => (propErrors || []).reduce((_, error) => {
        const { property, message } = error;
        if (property) {
            const pname = property?.startsWith(".") ? property.substring(1) : property;
            const prop = _[pname] || (_[pname] = {});
            prop[message] = error;
        }

        return _;
    }, {}), [propErrors]);

    const errors = useMemo(() => {
        if (!errorSchema) return [];

        const errors = [];
        dfsSearch([["", "", errorSchema]], ([prefix, name, current]) => {
            const fringe = [];
            Object.entries(current).forEach(([key, value]) => { if (key === ERRORS_KEY) {
                value = value.filter(x => x !== 'should be equal to constant');
                if (prefix && value.length) {
                    deduplicate(value).forEach(v => errors.push({
                        fieldId: makeFieldId(prefix),
                        path: prefix,
                        name,
                        data: (errorDataByField[prefix] || {})[v],
                        msg: v
                    }));
                }
            } else {
                fringe.push([prefix ? `${prefix}.${key}` : key, key, value]);
            }});
            return fringe;
        });

        return errors;
    }, [errorSchema, errorDataByField]);

    return (
        <Card className="form-errors">
            <CardHeader className="title" onClick={toggleExpand}>
                Errores
                <button className="expandable" onClick={toggleExpand}>
                    <i className={expanded ? "fa fa-caret-up" : "fa fa-caret-down"} />
                </button>
            </CardHeader>
            {expanded ? (<List className="form-error-list" type="unstyled">{(errors || []).map((error, idx) => (
                <ErrorItem key={idx} err={error} schema={schema} uiSchema={uiSchema}/>
            ))}</List>) : null}
        </Card>
    );
}

function translateOneError(error, schema){
    const schemaChain = jsonSchemas.collectSchemaChainFor(error.property.substring(1), schema);

    const title = schemaChain.map(sc => (sc.title || "").trim()).filter(x => !!x).pop() || "";

    return formErrors[error?.name] ? ({
        ...error,
        title,
        message: formErrors[error?.name](error)
    }) : error
}

export function transformErrors(errors, schema) {
    return errors && errors.map(error => translateOneError(error, schema));
};


function ErrorItem({err, schema}){
    const [hide, setHide] = useState();
    const {
        fieldId,
        name,
        data,
        path,
        msg: defaultMsg
    } = err;
    useEffect(() => {setHide();}, [err]);

    function onClickError(e){
        e.preventDefault();

        const element = document.getElementById(fieldId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    const [title, msg] = useMemo(() => {
        let title = data?.title;
        const msg = defaultMsg;

        if (msg.startsWith(':')) {
            return ['', msg.substring(1)];
        }

        if (!title && path) {
            const schemaChain = jsonSchemas.collectSchemaChainFor(path, schema);
            const pathTitle = schemaChain.map(sc => (sc.title || "").replaceAll('*', '').trim()).filter(x => !!x).pop() || "";
            if (pathTitle) title = pathTitle;
        }

        return [(title || name).replace(/[:*]/g, ''), msg];
    }, [data?.title, name, schema, path, defaultMsg]);

    return (<li className="error-item">
        <Button color="clear" className="text-left" onClick={onClickError}>
            {title} {msg}
        </Button>
    </li>);
}


function makeFieldId(field){
    return `root_${field.replace(/^\./, '').replaceAll('.', '_')}`;
}

export default ErrorListTemplate;