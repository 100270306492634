import React, { useMemo, useEffect } from "react";
import {QRCodeCanvas} from 'qrcode.react';
import { Button as RSButton } from "reactstrap";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { useJnx } from "../../util/jnx";
import getPathFromId from "../../util/getPathFromId";

function QRCode(props) {
    const {
        formData,
        formContext,
        formContext: {
            sideChannel
        },
        idSchema: { $id } = {},
        schema: {
            title,
            jsonata: jsonataComputation,
            color = "#000000",
            backgroundColor = "#ffffff",
            hideQR = false,
            downloadOption = true
        },
    } = props;
    const randomId = Math.floor(Math.random() * 100000);
    const rootFormData = useSideChannelSubscription(sideChannel, 0);
    const path = useMemo(() => getPathFromId($id), [$id]);
    const valueJnx = useJnx(jsonataComputation);
    
    const value = useMemo(() => valueJnx.eval(
        rootFormData || {}, path, {
            formData, rootFormData
        }
    ), [valueJnx, formData, rootFormData, path]);
    const downloadQR = () => {
        const canvas = document.getElementById(randomId);
        const pngURL = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        const downloadLink = document.createElement("a");
        downloadLink.href = pngURL;
        downloadLink.download = `${value}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div>
            <label className="control-label">
                {title}
            </label><br/>
            <QRCodeCanvas
                id={randomId}
                value={value}
                size={168}
                includeMargin
                bgColor={backgroundColor}
                fgColor={color}
                level="M"
                hidden={hideQR}
            /><br hidden={hideQR}/><br hidden={hideQR}/>
            <RSButton onClick={downloadQR} hidden={!downloadOption}> Download QR </RSButton>
        </div>
    )
}

export default QRCode;