import React, { useMemo } from "react";
import { Table } from "reactstrap";
import Notification from "../../Notification";

function useTableLayoutType({
    $id,
    arrayType,
    hasAddBtnInRow,
    hasToolbar,
    headers,
    itemsAreObjectType,
    itemsUiSchema,
    onEmptyMessage,
    positionButtons,
    props,
    rowClassNames,
}){
    return useMemo(() => {
        if (arrayType === 'cards') {
            return new CardsTableLayout({
                $id,
                arrayType,
                hasAddBtnInRow,
                hasToolbar,
                headers,
                itemsAreObjectType,
                itemsUiSchema,
                onEmptyMessage,
                positionButtons,
                props,
                rowClassNames,
            });
        } else {
            return new TabularTableLayout({
                $id,
                hasAddBtnInRow,
                hasToolbar,
                headers,
                itemsAreObjectType,
                itemsUiSchema,
                onEmptyMessage,
                props,
                rowClassNames,
            });
        }
    }, [
        arrayType,
        $id,
        itemsUiSchema,
        hasToolbar,
        itemsAreObjectType,
        headers, onEmptyMessage
    ]);
}

class CardsTableLayout {
    constructor({
        $id,
        hasToolbar,
        itemsAreObjectType,
        onEmptyMessage,
        positionButtons,
        rowClassNames,
    }) {
        this.isTable = false;
        this.rowClassNames = rowClassNames;
        this.$id = $id;
        this.positionButtons = positionButtons;
        this.itemsAreObjectType = itemsAreObjectType;
        this.positionButtons = positionButtons;
        this.rowClassNames = rowClassNames;
        this.hasToolbar = hasToolbar;
        this.onEmptyMessage = onEmptyMessage;
    }

    wrapArray(children) {
        return (<div className="array-cards">{children}</div>);
    }

    wrapBody(children) {
        return (<div className="array-item-list" key={`array-item-list-${this.$id}`}>
            {children}
        </div>);
    }

    wrapItem(id, props, onAddClick, rowIdx, rowCount, children, buttons, rowClassNames) {
        return (<div id={id} className={`has-buttons-position-${this.positionButtons || 'normal'} ${props.className} ${this.itemsAreObjectType ? 'for-object-row' : ''} ${rowClassNames}`}>
            <div>
                {children}
            </div>
            {buttons ? (<div className={`array-item-buttons col-xs-3 array-item-toolbox buttons-position-${this.positionButtons || `normal`}`} style={{ width: '100px' }}><div
                className="btn-group"
                style={{ display: "flex", justifyContent: "center" }}
            >
                {buttons}
            </div></div>) : null}
        </div>)
    }

    wrapItemField({
        name, uiSchema
    }, children) {
        return (<div
            key={name}
            className={(uiSchema[name] || {})['ui:cellClassName']}
        >
            {children}
        </div>)
    }

    wrapItemPanelRow({
        columnCount
    }, children) {
        return (<div className={`array-item panel-row ${this.rowClassNames}`}>
            {children}
        </div>);
    }

    wrapItemErrorList(children) {
        return (<div>
            {children}
        </div>);
    }

    makeEmptyItemField() {
        return (<td />);
    }

    makeSimpleItem(props) {
        return (<div className={this.hasToolbar ? "col-xs-9" : "col-xs-12"}>{props.children}</div>);
    }

    wrapAddButton(children) {
        return (<div>
            {children}
        </div>);
    }
 
    makeEmptyMessage() {
        return (
            <div><Notification color="warning">{this.onEmptyMessage}</Notification></div>
        );
    }
 
    makeHeaders() {
        return (null);
    }
}

class TabularTableLayout {
    constructor({
        $id,
        hasAddBtnInRow,
        hasToolbar,
        headers,
        itemsAreObjectType,
        itemsUiSchema,
        onEmptyMessage,
        props,
        rowClassNames,
    }) {
        this.isTable = true;
        this.$id = $id;
        this.hasAddBtnInRow = hasAddBtnInRow;
        this.hasToolbar = hasToolbar;
        this.headers = headers;
        this.itemsAreObjectType = itemsAreObjectType;
        this.itemsUiSchema = itemsUiSchema;
        this.onEmptyMessage = onEmptyMessage;
        this.props = props;
        this.rowClassNames = rowClassNames;
    }

    wrapArray(children) {
        return (<Table>{children}</Table>)
    }

    wrapBody(children) {
        return (<tbody className="array-item-list" key={`array-item-list-${this.$id}`}>
            {children}
        </tbody>);
    }

    wrapItem(id, props, onAddClick, rowIdx, rowCount, children, buttons, rowClassNames) {
        return (<tr id={id} className={`${props.className} ${this.itemsAreObjectType ? 'for-object-row' : ''} ${rowClassNames}`}>
            {children}
            {buttons ? (<td className="col-xs-3 array-item-toolbox" style={{ width: '100px' }}><div
                className="btn-group"
                style={{ display: "flex", justifyContent: "center" }}
            >
                {buttons}
            </div></td>) : null}
        </tr>);
    }

    wrapItemField({
        name, uiSchema
    }, children) {
        return (<td
            key={name}
            className={(uiSchema[name] || {})['ui:cellClassName']}
        >
            {children}
        </td>);
    }

    wrapItemPanelRow({
        columnCount
    }, children) {
        return (<tr className={`array-item panel-row ${this.rowClassNames}`}><td colSpan={columnCount}>
            {children}
        </td></tr>);
    }

    wrapItemErrorList(children) {
        return (<tr><td colSpan={this.headers?.length || 1}>
            {children}
        </td></tr>);
    }

    makeEmptyItemField() {
        return (<td />)
    }

    makeSimpleItem(props) {
        return (<td className={this.hasToolbar ? "col-xs-9" : "col-xs-12"}>{props.children}</td>)
    }

    wrapAddButton(children) {
        return (<tr><td colSpan={this.headers?.length || 1}>
            {children}
        </td></tr>)
    }

    makeEmptyMessage() {
        return (
            <tr><td colSpan={this.headers?.length || 1}><Notification color="warning">{this.onEmptyMessage}</Notification></td></tr>
        )
    }

    makeHeaders() {
        return (<thead>
            <tr className={`${this.itemsAreObjectType ? 'for-object-row' : ''}`}>
                {this.hasAddBtnInRow ? <th /> : null}
                {this.headers.map(({ field, Component, text }, idx) => (
                    <th
                        key={idx}
                        className={(this.itemsUiSchema[field] || {})['ui:cellClassName']}
                    >{Component ? (
                        <Component field={field} {...this.props} />
                    ) : (text)}</th>
                ))}
                {this.hasToolbar ? (<th />) : null}
            </tr>
        </thead>)
    }
}

export default useTableLayoutType;