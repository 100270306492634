import { useContext, useMemo } from "react";
import { Redirect, useRouteMatch } from "react-router";
import { AuthContext } from "../../context/AuthContext";
import DashboardApi from "../../api/DashboardApi";
import Dashboard from "../../components/Dashboard/index";
import InternalLayout from "../../components/layouts/InternalLayout";
import UWEContext from "../../context/UWEContext";
import useResourceLoader from "../../util/useResourceLoader";
import UWEEntityApi from "../../api/UWEEntityApi";
import Loader from "../../components/Loader";

function TrayView() {
  const auth = useContext(AuthContext);
  const { slug } = useRouteMatch().params;
  const { dashboards } = useContext(UWEContext);

  const [
    workflowInfo,
    loadingWorkflow,
    errorLoadingWorkflow
  ] = useResourceLoader(
    () => UWEEntityApi.getWorkflowInfoForSlug({ slug }),
    [slug]
  );

  const dashboardId = useMemo(
    () => dashboards?.filter(db => db.slug === slug).pop()?.id,
    [dashboards, slug]
  );

  const [
    dashboard,
    loadingDashboard,
    errorLoadingDashboard
  ] = useResourceLoader(
    async () => (dashboardId ? DashboardApi.get({ id: dashboardId }) : null),
    [dashboardId]
  );

  const TrayComponent = useMemo(() => {
    if (!dashboard) return null;

    const schema = {
      wrapperType: "internal",
      ...dashboard,
      schema: dashboard.definition
    };

    if (
      Array.isArray(dashboard?.definition?.elements?.[0]?.entityType) &&
      dashboard?.definition?.elements?.[0]?.entityType.length === 0
    ) {
      const entities =
        auth?.user?.user?.organizationalUnitRoles?.find(x => x.mainRole)?.role?.entities ||
        auth?.user?.user?.organizationalUnitRoles?.[0]?.role?.entities;
      const traysAvailable =
        entities && Object.keys(entities).length
          ? Object.entries(entities)
              .filter(([key, value]) => value.homeTray)
              .map(([key, value]) => key)
          : false;

      if (!traysAvailable) return false;

      schema.schema = {
        ...dashboard.definition,
        elements: [
          { ...dashboard.definition.elements[0], entityType: traysAvailable }
        ]
      };
    }

    return Dashboard.HOC(schema);
  }, [auth, dashboard]);

  const shouldRedirect = !!workflowInfo?.redirect;

  const isLoading = loadingDashboard || loadingWorkflow;
  
  return isLoading ? 
  (
    <InternalLayout>
      <Loader />
    </InternalLayout>
  ) : 
  TrayComponent ? 
      (
        <TrayComponent />
      ) : 
      shouldRedirect ? 
          (
            <Redirect to={workflowInfo.redirect} />
          ) : 
          (
            <ErrorTray />
          );
}

const ErrorTray = Dashboard.HOC({
  wrapperType: "internal",
  schema: {
    type: "dashboard",
    elements: [
      {
        type: "text",
        label: "Tray not Found"
      }
    ]
  }
});

export default TrayView;
