import { Trans, t } from "@lingui/macro";
import React, { useMemo, useState, useEffect, useRef } from "react";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import ObjectSchemaApi from "../../../api/ObjectSchemaApi";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import useResourceLoader from "../../../util/useResourceLoader";
import useLoader from "../../../util/useLoader";
import JsonTreeEditor from "../../../components/JsonTreeEditor";
import useSearchParams from "../../../util/useSearchParams";
import JsonComponent from "../../../components/FormBuilder/JsonComponent";


const SHOW_TABS = {
    tree: t`Tree`,
    json: t`Json`,
}

function EditObjectSchema({ objectSchemaId, setObjectSchema: setObjectSchemaId}) {
    const [resetCt, setResetCt] = useState();
    const [objectSchema, loadingObjectSchema, errorLoadingObjectSchema, setObjectSchema] = useResourceLoader((
        () => objectSchemaId === "new" ? {
            name: t`New Entity Type`,
            schema: {},
            assignmentSlots: {},
            metadataObjectMap: {},
            roleMap: {},
        } : ObjectSchemaApi.get({ objectSchemaId })
    ), [resetCt]);

    const [[show, setShow]] = useSearchParams({
        show:'tree'
    });

    const [loadingSetObjectSchema, errorLoadingSetObjectSchema, loadFn] = useLoader();
    const loading = loadingObjectSchema || loadingSetObjectSchema;
    const error = errorLoadingObjectSchema || errorLoadingSetObjectSchema;
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    const [newSchema, setNewSchema] = useState();

    function save() {
        return loadFn(async () => {
            if (objectSchemaId === "new") {
                const saveOS = await ObjectSchemaApi.create(newSchema);
                setObjectSchema(saveOS);
                setObjectSchemaId(saveOS.id);
            } else {
                const savedOS = await ObjectSchemaApi.update(objectSchemaId, newSchema);
                setObjectSchema(savedOS);
            }
        });
    }

    // function onSchemaChange(newSchema) {
    //     try {
    //         setNewSchema(JSON.parse(newSchema.json));
    //     } catch {
    //         return;
    //     }
    // }

    useEffect(() => {
        if (objectSchema !== undefined) setNewSchema(objectSchema);
    }, [objectSchema])

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    return ( loading ? (
        <Loader />
    ) : (<>
        {error ? (
            <Notification error={error} />
        ) : null}
        {objectSchema ? (<div>
            <h3>{objectSchemaId === "new" ? (<>
                <Trans>Creating Entity Type: {objectSchema.name}</Trans>
            </>) : (<>
                <Trans>Editing Entity Type: {objectSchema.name}</Trans>
            </>)}</h3>
            <UncontrolledDropdown>
                <DropdownToggle caret>{SHOW_TABS[show]}</DropdownToggle>
                <DropdownMenu>{Object.entries(SHOW_TABS).map(([key, title]) => (<DropdownItem key={key} onClick={() => setShow(key)}>
                    {title}
                </DropdownItem>))}</DropdownMenu>
            </UncontrolledDropdown>
            {show === "tree" ? (
                <JsonTreeEditor mode="object" value={newSchema} onChange={setNewSchema} />
            ): null}
            {show === "json" ? (
                <JsonComponent value={newSchema} onChange={setNewSchema} />
            ): null}
            {/* <JSONInput
                placeholder={objectSchema}
                width={windowSize[0]*.8} height={windowSize[1]*.7}
                onBlur={onSchemaChange}
                onKeyPressUpdate={false}
                locale={locale}
            /> */}
            <Button color="success" disabled={!newSchema} onClick={save}><Trans>Save</Trans></Button>
        </div>) : null}
    </>));
}

export default EditObjectSchema;