import React from "react";

function StaticTextBlurb(props) {
    const {
        schema: { content },
    } = props;

    return (
        <div dangerouslySetInnerHTML={{ __html: content }} />
    )
}

export default StaticTextBlurb;