import React, { useEffect, useMemo, useState } from 'react';
import {
    Button,
    Card,
} from 'reactstrap';
import UWEEntityApi from '../../api/UWEEntityApi';
import useResourceLoader from '../../util/useResourceLoader';
import Loader from '../Loader';
import Notification from '../Notification';
import { toDrDateTime } from '../../util/dates';
import { DATETIME_FORMAT } from '../../constants';
import { useJnx } from '../../util/jnx';
import { useSideChannelSubscription } from '../../util/useSideChannel';
import { concatenatePaths } from '../../util/mapObject';
import getPathFromId from '../../util/getPathFromId';

function Notes(props) {

    const {
        formContext,
        formObject,
        formContext: {
            setFormDataValues,
            bindings: fcBindings,
            sideChannel
        },
        schema: {
            title,
        },
        idSchema: { $id } = {},
        uiSchema: {
            'ui:onAdd': onAddExpr,
            'ui:readonly': readonly = false,
        },
        onChange,
    } = props;

    const path = useMemo(() => getPathFromId($id), [$id]);

    const entityId = formContext?.fileField?.postArgs?.entityId;

    const [newNote, setNewNote] = useState('');
    const [loading, setLoading] = useState(false);

    const [notes, loadingNotes, error, setNotes] = useResourceLoader(
        () => UWEEntityApi.getUWEEntityNotes({ entityId }),
        [entityId],
        []
    );

    const bindings = useMemo(() => ({
        ...fcBindings,
        formObject,
        formContext,
        notes
    }), [formObject, fcBindings, formContext]);

    const functionBinds = useMemo(() => ({ set: setFormDataValues }), [setFormDataValues]);
    const onAddJnx = useJnx(onAddExpr, { functionBinds });

    const rootFormData = useSideChannelSubscription(sideChannel, 0);

    async function submitNewNote() {
        if (newNote) {
            setLoading(true);
            const addedNote = await UWEEntityApi.createUWEEntityNote({ entityId, note: newNote, sendNotification: true });
            setNotes(notes => [addedNote, ...notes]);
            setNewNote('');
            setLoading(false);
            if (onAddJnx) {
                onAddJnx.evalAsync(rootFormData, path, { ...bindings });
            }
        }
    };
    useEffect(() => {
        onChange(notes)
    }, [notes])
    return loadingNotes || loading ? <Loader>{loading ? "Agregando nota" : null}</Loader> : (
        <>
            {error ? <Notification color="danger">{JSON.stringify(error)}</Notification> : null}
            <UWEEntityNoteSidePanelComponent
                title={title}
                readonly={readonly}
                $id={$id}
                newNote={newNote} setNewNote={setNewNote} submitNewNote={submitNewNote}
                notes={notes}
            />
        </>
    );
}


function UWEEntityNoteSidePanelComponent({
    readonly,
    $id,
    title,
    newNote, setNewNote, submitNewNote,
    notes,
}) {
    return (<div className="uweentity-note-sidepanel-component">
        {title !== " " ?
            <p id={$id} style={{ borderBottom: '1px solid #dee2e6', paddingBottom: 20 }} className="field-description">{title}</p> : null}
        {!readonly ? (<div className="row">
            <div className='col-lg-10'>
                <textarea row="3" className='form-control' data-cy="text-note" value={newNote} onChange={({ target: { value } }) => setNewNote(value)} />
            </div>
            <div className='col-lg-2'>
                <Button color="primary" data-cy="button-add-note" onClick={submitNewNote}>Añadir Nota Nueva</Button>
            </div>
        </div>) : null}
        <div className={notes.length ? "notes-list" : "not-notes-list"}>
            {!notes.length ? (
                <Notification color="warning">No hay notas agregadas</Notification>
            ) : null}
            {notes.map(({ createdAt, message, user }, idx) => (
                <div className="uweentity-note-container" key={idx}>
                    <div className="uweentity-note-header">
                        <span className="uweentity-note-date-time">{toDrDateTime(createdAt).format(DATETIME_FORMAT)}</span>
                        <span className="uweentity-note-user">{user?.name || ''}</span>
                    </div>
                    <div className="uweentity-note-text">{message}</div>
                </div>
            ))}
        </div>
    </div>)
}

export default Notes;
