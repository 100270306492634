import React, { useState, useEffect, useCallback } from "react";
import { Trans, t } from "@lingui/macro";

import FormApi from "../../../api/FormApi";
import FormBuilder from "../../../components/FormBuilder";
import Loader from "../../../components/Loader";
import Notification from "../../../components/Notification";
import useResourceLoader from "../../../util/useResourceLoader";
import useLoader from "../../../util/useLoader";
import useTitle from "../../../util/useTitle";

const MAKE_NEW_FORM = () => ({
    objectSchema: null,
    name: "new-form",
    title: t`New Form`,
    displayName: "NewForm",
    page: "new-form",
    commentFieldsMap: {},
    sendBack: {},
    options: {
        hasSaveProgressButton: true,
        submitButtons: {}
    },
    schema: {},
    uiSchema: {},
    permissionsMap: {}
});

function EditForm({ formId, setFormId }) {
    const [resetCt, setResetCt] = useState(0);
    const [loadedForm, loadingForm, errorLoadingForm] = useResourceLoader((
        () => formId === "new" ? MAKE_NEW_FORM() : FormApi.get({ formId })
    ), [resetCt]);

    const [loadingSave, errorLoadingSave, loadFn] = useLoader();
    const loading = loadingForm || loadingSave;
    const error = errorLoadingForm || errorLoadingSave;

    const [dirtyForm, setDirtyForm] = useState();

    const onSave = useCallback(() => {
        loadFn(async () => {
            if (dirtyForm) {
                const updatedForm = await FormApi.update(formId, dirtyForm);
                if (formId === 'new' && updatedForm?.id) {
                    setFormId(updatedForm.id);
                }
            }
            setResetCt(new Date().getTime());
            setDirtyForm();
        })
    }, [loadFn, dirtyForm]);

    const onReset = useCallback(() => { setDirtyForm() }, []);

    useEffect(() => { if (loadedForm !== undefined) { onReset() } }, [loadedForm]);

    const form = dirtyForm || loadedForm;

    useTitle(form ? (
        formId === 'new' ? t`New Form` : t`Edit Form | ${form.name}`
    ) : t`Loading`);

    return loading ? (
        <Loader />
    ) : (error ? (
        <Notification error={error} />
    ) : (!form ? (
        <Notification><Trans>Could not load Form.</Trans></Notification>
    ) : (<>
        <h2>
            {formId === "new" ? (
                <Trans>Creating form {form.name}</Trans>
            ) : (
                <Trans>Editing form {form.name}</Trans>
            )}
            {dirtyForm ? <small><Trans>(Unsaved Changes)</Trans></small> : null}
        </h2>
        <FormBuilder
            form={form}
            onSave={onSave}
            onChange={setDirtyForm}
            onReset={onReset}
        />
    </>)));
}

export default EditForm;