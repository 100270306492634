const items = [
    ["expedientes"    ,  4],
    ["registro"       ,  3],
    ["v_mant"         ,  1],
    ["v_uso"          ,  1],
    ["archivo"        ,  1],
    ["transferencia"  ,  1],
    ["tarjeta"        ,  1],
    ["bajas"          ,  1],
    ["marbete"        ,  2],
    ["licencias"      ,  2],
    ["accidentes"     ,  1],
    ["home"           ,  1],
    ["newlicense"     , 21],
    ["newgas"         ,  1],
    ["newautorizacion",  1],
].map(([icon, parts]) => ({icons:{
    base: `dimo-icon dimo-icon-${icon}`,
    paths: range(parts).map(p => `p${p + 1}`)
}}));

const dimoIcons = {
    description: 'Dimo Icons',
    image: items[0],
    items,
}

function range(n) {
    return Array.from(Array(n).keys());
}


export default dimoIcons;
