import ExternalLayout from "../components/layouts/ExternalLayout";
import MessageView from "../components/MessageView";


function ThankyouMessage(){
    return <ExternalLayout>
        <MessageView title="Formulario Sometido">
            <div>Su solicitud ha sido sometida.</div>
            <br />
            Gracias
        </MessageView>
    </ExternalLayout>;
}


export default ThankyouMessage;