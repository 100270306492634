import React, { useState, useContext, Fragment } from 'react';
import {
    Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Button,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavbarText
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext';
import { Logo } from "./_general/Logo";

import Clock from './Clock';
import AppContext from '../context/AppContext';
import { LABEL } from '../LanguageConstant';
import NavNotificationPanel from './NavNotificationPanel/NavNotificationPanel';


function NavMenu({ logoOnly }) {
    const auth = useContext(AuthContext);
    const user = auth?.user?.user;
    const permissions = ((auth || {}).user || {}).permissions || {};
    const [collapsed, setCollapsed] = useState(() => {
        const vpwidth = window.innerWidth;
        return vpwidth > 1000;
    });
    function toggleNavbar() {
        setCollapsed(!collapsed);
    }
    const { navbarContent } = useContext(AppContext);

    const manageAnything = permissions.ManageUsersInUnit || permissions.ManageOrganizationalUnits;

    return (
        <header><Navbar
            className="navbar-toggleable-sm mb-3"
            fixed="top"
            expand="sm"
        >
            <NavbarBrand className="nav-logo-icon" tag={Link} to="/"> <Logo width={57} height={58} /></NavbarBrand>
            {!user ?
                <>
                    <Button className="btn-primary2 btn-sm" tag={Link} to="/anonymous/1/workflow/autorizacion-viaje/new" >Autorización de Viajes</Button>
                    <Button className="ml-2 btn-primary2 btn-sm" tag={Link} color="primary" to="/anonymous/1/workflow/bitacora-viajes/new" >Bitácora de Viajes</Button>
                </>
                : null
            }
            {logoOnly ? null : (<>
                <NavbarToggler onClick={toggleNavbar} className="mr-2 is-collapsed" />
                {auth.user ? (<span className="nav-notification-mobile"><NavNotificationPanel /></span>) : null}
                <Collapse isOpen={!collapsed} navbar>
                    <Nav className="navbar-nav">
                        <NavbarText><Clock className="nav-clock" /></NavbarText>
                        {navbarContent || null}
                    </Nav>
                    <Nav className="navbar-nav">
                        {auth.user ? (<NavbarText className="d-flex align-items-center">
                            <NavLink tag={Link} to="/faq"><span className="navbar-help-text">{LABEL.help}</span></NavLink>
                        </NavbarText>) : null}
                        
                        {manageAnything ? (<NavbarText>
                            <NavLink tag={Link} to="/system-settings" ><i className="fa fa-cog nav-icon" /></NavLink>
                        </NavbarText>) : null}

                        {auth.user ? (<NavItem><NavNotificationPanel /></NavItem>) : null}
                         
                        {auth.user ? (<NavItem><UncontrolledDropdown className="user-nav-dropdown">
                            <DropdownToggle caret nav>
                                <i className="fa fa-user nav-icon" />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem className="user-info d-flex align-items-center" tag={Link} to="/profile" >
                                    <UserDataPane icon={auth?.user?.actualUser ? "user-secret" : ""} user={auth.user} />
                                </DropdownItem>
                                {auth?.user?.actualUser ? (<>
                                    <hr className="dropdown-divider" />
                                    <DropdownItem className="user-info d-flex align-items-center" >
                                        <UserDataPane icon="user" user={auth.user.actualUser} />
                                    </DropdownItem>
                                    <hr className="dropdown-divider" />
                                    <DropdownItem className="d-flex align-items-center" tag={Link} to="/" onClick={() => auth.stopImpersonation()}>
                                        Dejar de Impersonar
                                    </DropdownItem>
                                </>) : null}
                                <hr className="dropdown-divider" />
                                {/* <DropdownItem className="d-flex align-items-center" tag={Link} to="/faq">
                                    Frequently Asked Questions
                                </DropdownItem> */}
                                <hr className="dropdown-divider" />
                                <DropdownItem className="d-flex align-items-center" tag={Link} to="/" onClick={() => auth.logout()}>
                                    Log Out
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown></NavItem>) : null}
                    </Nav>
                </Collapse>
            </>)}
        </Navbar></header>
    );
}


function UserDataPane({ user, icon }) {
    const {
        user: {
            firstName,
            lastName,
            accessProfile
        } = {},
        organizationalUnits,
    } = user || {};

    return (<div>
        {icon ? <i className={`fa fa-${icon}`} /> : null}
        <div className="user">
            {` ${firstName || ""}  ${lastName || ""}`}
        </div>
        {accessProfile ? (
            <div className="role">{accessProfile?.displayName || "----"}</div>
        ) : null}
        {(organizationalUnits || []).map(({code, roles}, o_index) => (<Fragment key={o_index}>
            <div className="organization">{code}</div>
            {(roles || []).map((role_our, r_index) => (
                <div key={r_index} className="role">{role_our?.role?.name}</div>
            ))}
        </Fragment>))}
    </div>);
}


export default NavMenu;