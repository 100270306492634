import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';


export const SequenceApi = {
    async list({ offset, size, sortBy }) {
        return (await checkErrors(await fetchApi.api.get('manage/sequences', { query: { offset, size, sortBy } }))).data;
    },

    async get({ id }) {
        return (await checkErrors(await fetchApi.api.get(`manage/sequences/${id}`))).data;
    },

    async update(id, data) {
         return (await checkErrors(await fetchApi.api.put(`manage/sequences/${id}`,{ data }))).data;
    },

    async create(data) {
        return (await checkErrors(await fetchApi.api.put(`manage/sequences/`,{ data }))).data;
   },
}


export default SequenceApi;
