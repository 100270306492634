import Dashboard from "./Dashboard";
import DC from "./fields";
import TextDisplay from "./TextDisplay";
import WorkTray from "./WorkTray";
import WorkTrayIcon from "./WorkTrayIcon";
import EntityWidget from "./EntityWidget";
import EntitySummary from "./EntitySummary";
import SummaryChart from "./SummaryChart";
 
export const DASHBOARD_COMPONENTS = DC;
DASHBOARD_COMPONENTS.worktray = WorkTray;
DASHBOARD_COMPONENTS.entity = EntityWidget;
DASHBOARD_COMPONENTS.button = WorkTrayIcon;
DASHBOARD_COMPONENTS.text = TextDisplay;
DASHBOARD_COMPONENTS.summary = EntitySummary;
DASHBOARD_COMPONENTS.chart = SummaryChart;


export default Dashboard;