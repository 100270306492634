import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';


export const DashboardApi = {
    async list({
        forMe,
        all, offset, size, sortBy,
    }) {
        return (await checkErrors(await fetchApi.api.get('dashboards', { query: {
            forMe,
            all, offset, size, sortBy
        } }))).data;
    },

    async get({ id }) {
        return (await checkErrors(await fetchApi.api.get(`dashboards/${id}`))).data;
    },

    async update(id, schema) {
         return (await checkErrors(await fetchApi.api.put(`manage/dashboards/${id}`,{data: schema}))).data;
    },

    async create(schema) {
        return (await checkErrors(await fetchApi.api.post(`manage/dashboards/`,{data: schema}))).data;
   },
}


export default DashboardApi;
