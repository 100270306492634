import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import appProps from '../appProps';


const AppContext = React.createContext();


AppContext.Component = function({ children }){
    const [props, setProps] = useState(appProps);

    const setProp = useCallback((prop, value) => {
        setProps(props => ({...props, [prop]: value}))
    }, []);

    const setters = useMemo(() => Object.keys(appProps).reduce((_, prop) => {
        const setterName = `set${prop.substr(0, 1).toUpperCase()}${prop.substr(1)}`;
        _[setterName] = value => setProp(prop, value);
        return _;
    }, {}), []);

    const value = useMemo(() => ({
        ...props,
        ...setters,
        setProp,
    }), [props]);

    return <AppContext.Provider value={value} >{children}</AppContext.Provider>;
}


AppContext.Prop = function(props) {
    const { prop, value } = props;
    const app = useContext(AppContext);
    const dependencies = Object.entries(props).filter(([k, v]) => /^dep\d+$/.test(k)).map(([k, v]) => v);
    useEffect(() => {
        const oldValue = app[prop];
        console.log(`setting app prop ${prop} to `, value);
        app.setProp(prop, value);
        return () => {
            app.setProp(prop, 'unsetTo' in props ? props.unsetTo : oldValue);
        }
    }, dependencies);
    return null;
}

Object.keys(appProps).forEach(prop => {
    const compName = `Set${prop.substr(0, 1).toUpperCase()}${prop.substr(1)}`;
    AppContext[compName] = ({value}) => <AppContext.Prop prop={prop} value={value} />;
});


export default AppContext;