import React, { useCallback, useMemo } from 'react';
import Select from "react-select";


const PATTERN = { type: "select", options: { $notEmpty: true }, multiple: true };


function MultiSelectField(props) {

    const { id: idx, fieldProps: prop, value: propValue, onChange } = props;
    const { delimiter = ' ' } = prop;

    const { options, property, label } = prop;
    const optionsMap = useMemo(() => options.reduce((_, o) => {
        _[o.value] = o;
        return _;
    }, {}), [options]);

    const value = useMemo(() => propValue ? propValue.split(delimiter).map(x => !!x && optionsMap[x]).filter(x => !!x) : [], [propValue, optionsMap]);
    // const value = useMemo(() => propValue.split(' ').filter(item => 
    //     item && options.some(({value}) => value === item)
    // ).map(item => ({value: item, })), [propValue, optionsMap]);

    const onMultipleChange = useCallback((value) => {
        value = value.map(({ value }) => value).filter(x => !!x).join(delimiter);
        onChange(value);
    }, [property, onChange]);

    return (<>
        <label className="title">{label}</label>
        <Select isMulti options={options} name={property} value={value} onChange={onMultipleChange} />
    </>);
}


MultiSelectField.pattern = PATTERN;


export default MultiSelectField;